import React, { useState } from "react";
import {
  Modal,
  Backdrop,
  CircularProgress,
  Grid,
} from "@material-ui/core";
import { Form, Field, Formik } from "formik";
import FormikTextField from "../shared/TextField";
import FormikAutocomplete from "../shared/AutoComplete";
import { getUserEmail } from "../../helpers/utility";
import { PrimaryButton } from "../RankTracking/style";
import CustomToolTip from "../shared/CustomToolTip";

const AddNewProjectForm = (props) => {
  const handleClose = () => {
    props.setOpenModal(false);
    if (formValues && existProject === null) {
      props.setSelectedProject({
        project: formValues.project_name,
        project_id: props.savedProjectId.project_id,
        organization: formValues.organization.organization,
        organization_id: formValues.organization.organization_id,
        department: formValues.department.department,
        domain: formValues.client_domains,
        status: true
      })
      props.fetchProjects(getUserEmail());
      // !(props.filteredClient.includes(formValues.organization.organization_id)) && props.clearAll();
    } else {
      setExistProject(null);
    }
  };

  const { projects, accessible_clients, formValues, setFormValues } = props;
  const divisions = JSON.parse(localStorage.getItem("divisions"));
  const projectNameRegex = /^[a-zA-Z0-9_ ]+$/;
  const domainRegex =
    /[(http(s)?):\/\/(www\.)?a-zA-Z0-9-@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
  let errors = {};
  const [existProject, setExistProject] = useState(null);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      className="modal-wrapper"
      disableScrollLock={true}
      open={props.openModal}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      closeicon={
        <img
          className="closeIcon"
          alt="closeIcon"
          src={"/images/Close-X_Icon.svg"}
        />
      }
    >
      <div className="create-project-wrapper">
        <div className="create-project-top-bar"></div>
        <div className="modal-content">
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              {!props.isCreatedProject && (
                <div className="row-image">
                  <img
                    className="closeIcon"
                    alt="closeIcon"
                    src={"/images/Close-X_Icon.svg"}
                    onClick={() => handleClose()}
                  />
                </div>
              )}
            </Grid>
            <Grid item xs={11}>
              <Grid container>
                <Grid item xs={12}>
                  {!props.isCreatedProject && existProject === null && (
                    <>
                      <div className="row">
                        <p className="create-project-title">
                          Create a new project
                        </p>
                      </div>
                    </>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                {props?.isLoadingCreateProject ? (
                  <div className="prj-loader-container"><CircularProgress /></div>
                ) : props.isCreatedProject || existProject?.project ? (
                  <div className="center">
                    <img
                      src="/images/Icon_Success.svg"
                      className="prj-success-icon"
                      alt="success-icon"
                    />
                    <p className="project-success-message">
                      {existProject?.project ? "Project already exist !" : "Project successfully created"}
                    </p>
                    <PrimaryButton
                      color="primary"
                      onClick={() => handleClose()}
                    >
                      Close
                      <img
                        src={"/images/Icon_Arrow-White.png"}
                        alt="Black arrow icon"
                      />
                    </PrimaryButton>
                  </div>
                ) : (
                  <Formik
                    initialValues={{
                      department: "",
                      client_domains: "",
                      //competitor_domains: "",
                      project_name: "",
                      organization: "",
                    }}
                    validate={(values) => {
                      !values.organization
                        ? (errors.organization = "Required")
                        : (errors.organization = "");
                      !values.department
                        ? (errors.department = "Required")
                        : (errors.department = "");
                      !values.client_domains
                        ? (errors.client_domains = "Required")
                        : !values.client_domains.match(domainRegex)
                          ? (errors.client_domains = "Please enter a valid URL")
                          : (errors.client_domains = "");
                      // !values.competitor_domains
                      //   ? (errors.competitor_domains = "Required")
                      //   : values.competitor_domains
                      //       .replace(/\n/g, ",")
                      //       .split(",")
                      //       .some(function checkAdult(age) {
                      //         return !age.trim()?.match(domainRegex);
                      //       })
                      //   ? (errors.competitor_domains =
                      //       "Please enter a valid URL")
                      //   : (errors.competitor_domains = "");
                      !values.project_name
                        ? (errors.project_name = "Required")
                        : !values.project_name.match(projectNameRegex)
                          ? (errors.project_name =
                            "Project name format incorrect. Supports alphanumeric characters and underscores exclusively.")
                          : (errors.project_name = "");
                      return !errors.organization &&
                        !errors.department &&
                        !errors.client_domains &&
                        // !errors.competitor_domains &&
                        !errors.project_name
                        ? {}
                        : errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      const org = projects?.find(project => values.organization.organization_id === project.organization_id);
                      const project = org && org?.data?.find(data => data.project.toLowerCase() === values.project_name.toLowerCase()); //checking the duplicate of project
                      project ? setExistProject(project) : setExistProject(null);
                      setFormValues(values);
                      setTimeout(() => {
                        setSubmitting(false);
                        values.client_domains = values.client_domains.replace(
                          /[^\x00-\x7F]/g,
                          ""
                        );
                        // values.competitor_domains = values.competitor_domains.replace(
                        //   /\n/g,
                        //   ","
                        // );
                        values["email"] = getUserEmail();
                        project === undefined && props.saveProject(values)
                      }, 500);
                    }}
                    render={({
                      submitForm,
                      isSubmitting,
                      values,
                      setFieldValue,
                    }) => (
                      <Form className="new-project-form">
                        <div className="new-project-label">
                          Client Name <span className="required">*</span>
                        </div>
                        <Field
                          name="organization"
                          label="Select client"
                          component={FormikAutocomplete}
                          variant="outlined"
                          options={
                            (accessible_clients.length > 0 &&
                              accessible_clients) ||
                            (projects.length > 0 &&
                              projects.filter((project) => project.status))
                          } //client drop down restriction implementation
                          getOptionLabel={(option) =>
                            option &&
                            `${option.organization}(${option.organization_id})`
                          }
                        />

                        <div className="new-project-label">
                          Division <span className="required">*</span>
                          <CustomToolTip title={`Data costs are billed by division based on this selection. For more information, 
                          please reference the Guru card "How Many Search Terms Can I Track? How Much Does it Cost?"`} />
                        </div>
                        <Field
                          name="department"
                          label="Select your division"
                          component={FormikAutocomplete}
                          variant="outlined"
                          options={divisions}
                          getOptionLabel={(option) => option?.department}
                        />
                        <div className="new-project-label">
                          Tracked Client Site <span className="required">*</span>
                          <CustomToolTip title={`The tracked site should reflect the area of your client's website for which you are performing analysis. 
                          Entering the domain only (example.com) will result in broad client ownership for any returned URL associated to that domain. 
                          Entering a domain with a subfolder (example.com/subfolder) will result in a more specific client ownership and allow you to focus on a specific section of the website.`} />
                        </div>
                        <Field
                          component={FormikTextField}
                          name="client_domains"
                          label="Enter client site"
                          variant="outlined"
                          singleLine={true}
                        />
                        <p className="client-domain-helpertext">
                          Protocol (eg, https:// or http://) and regex are NOT
                          supported
                        </p>
                        {/* <Field
                    name="competitor_domains"
                    label="Competitor domain(s) *"
                    component={FormikTextField}
                    variant="outlined"
                  /> */}
                        <div className="new-project-label">
                          Project Name <span className="required">*</span>
                        </div>
                        <Field
                          name="project_name"
                          label="Name your project"
                          component={FormikTextField}
                          variant="outlined"
                          singleLine={true}
                        />
                        <div className="create-project-button">
                          <PrimaryButton
                            disabled={isSubmitting}
                            onClick={submitForm}
                          >
                            Create new project
                            <img
                              src={"/images/Icon_Arrow-White.png"}
                              alt="Black arrow icon"
                            />
                          </PrimaryButton>
                        </div>
                      </Form>
                    )}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div className="create-project-bottom-bar"></div>
      </div>
    </Modal>
  );
};

export default AddNewProjectForm;
