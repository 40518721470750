import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route } from "react-router-dom";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import reportWebVitals from "./reportWebVitals";
import { FEEDBACK_LINK, HELP_LINK, ROADMAP_LINK } from "./helpers/redirectionLinks";

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Route exact path="/help" component={() => { window.location = HELP_LINK; return null; }} ></Route>
      <Route path="/feedback" component={() => { window.location = FEEDBACK_LINK; return null; }}></Route>
      <Route path="/roadmap" component={() => { window.location = ROADMAP_LINK; return null; }}></Route>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
