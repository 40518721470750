import styled from "styled-components";

export const IframeWrap = styled.div`
  height: calc(100vh - 5rem);
    iframe {
      width: 100%;
      height: 100%;
    } 
    .iframe-progress-container{
    align-items: center;
    display: flex;
    height: 100%;
    } 
`;
