export const RESOURCES = "https://app.getguru.com/collections/ojx08/Supernova";
export const SEARCH_DISCOVERY = "https://forms.monday.com/forms/e2b43e1f90178e5d5275898dba648497?r=use1";
export const OTHER_RESOURCES = "https://app.getguru.com/collections/ojx08/Supernova";
export const HELP_LINK = "https://app-us2.wrike.com/workspace.htm?acc=5271914#/forms?formid=627032";
export const FEEDBACK_LINK = "https://app-us2.wrike.com/workspace.htm?acc=5271914#/forms?formid=627291";
export const ROADMAP_LINK = "https://app-us2.wrike.com/workspace.htm?acc=5271914#folder/1229542933/tableV2?filters=status%3Dactive%253Bcompleted%253Bdeferred%26projectStatus%3Dactive%253Bcompleted%253Bdeferred&spaceId=1111689748&viewId=216995916";

export const SS_TRACK_ONE_TIME = "https://app.getguru.com/card/iGg9MnAT/How-to-Use-OneTime-Uploads-in-Organic-Rank-Tracking";
export const SS_SERP_FEATURE = "https://app.getguru.com/card/i4ARrrgT/SERP-Feature-Types-in-Organic-Rank-Tracking";
export const SS_RANK_TRACK = "https://app.getguru.com/card/ikAMkbRT/Intro-to-Organic-Rank-Tracking";
export const SS_RANK_TRACK_DASHBOARD = "https://app.getguru.com/boards/TyMeMrgc/Organic-Rank-Tracking-Dashboards";
export const SS_CLIENT_SPECIFIC_DATASET = "https://app.getguru.com/card/T5AoXEMc/Intro-to-ClientSpecific-Datasets-CSDS";
export const SS_ORGANIC_RANK_DATA = "https://app.getguru.com/card/TLgyjGqc/Intro-to-Exploring-Data-Lakes";

