import {
  SUPERNOVA_API,
  API_EMBED_URL,
  API_DASHBOARD_SLUG
} from "../../api";
import * as ACTION from "../actionTypes";
import {
  clearAll
} from "../../helpers/utility";


export function getEmbedUrl(id,filters) {
  return dispatch => {
    dispatch(getEmbedUrlBegin());
    const urlDetailsId = id ? `?dashboard_id=${id}`: '';
    const urlDetailsFilters = id && filters ? `&filters=${encodeURIComponent(filters)}` : "";
    return SUPERNOVA_API.get((`${API_EMBED_URL}${urlDetailsId}${(urlDetailsFilters)}`), {}, {})
      .then(res => {
        dispatch(getEmbedUrlSuccess(res));
      })
      .catch(err => {
        if (err?.response?.status === 401) {
          clearAll();
          window.location.reload();
        }
        dispatch(getEmbedUrlError(err));
      });
  };
}

export function getDashboardSlug(id) {
  return dispatch => {
    dispatch(getDashboardSlugBegin());
    const urlDetailsId = id ? `?dashboard_id=${id}` : '';
    return SUPERNOVA_API.get((`${API_DASHBOARD_SLUG}${urlDetailsId}`), {}, {})
      .then(res => {
        dispatch(getDashboardSlugSuccess(res));
      })
      .catch(err => {
        if (err?.response?.status === 401) {
          clearAll();
          window.location.reload();
        }
        dispatch(getDashboardSlugError(err));
      });
  }
}

export const getEmbedUrlBegin = () => ({
  type: ACTION.FETCH_EMBED_URL_BEGIN
});

export const getEmbedUrlSuccess = list => ({
  type: ACTION.FETCH_EMBED_URL_SUCCESS,
  payload: list
});

export const getEmbedUrlError = () => ({
  type: ACTION.FETCH_EMBED_URL_ERROR,
});

export const getDashboardSlugBegin = () => ({
  type: ACTION.FETCH_DASHBOARD_SLUG_BEGIN
});

export const getDashboardSlugSuccess = list => ({
  type: ACTION.FETCH_DASHBOARD_SLUG_SUCCESS,
  payload: list
});

export const getDashboardSlugError = () => ({
  type: ACTION.FETCH_DASHBOARD_SLUG_ERROR,
});

