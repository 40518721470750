import React from "react";
import { Select } from "antd";
import { fieldToTextField } from "formik-material-ui";

const FormikSelect = ({ textFieldProps, ...props }) => {
  const {
    form: { setTouched, setFieldValue },
  } = props;
  const { error, helperText, ...field } = fieldToTextField(props);
  const { name } = field;
  return (
    <Select
      {...props}
      onChange={(value, key) => {
        setFieldValue(name, value.filter(function(entry) { return entry.trim() != ''; }));
      }}
      onBlur={() => setTouched({ [name]: true })}
      mode={props.mode}
      placeholder={props.placeholder}
      multiline
    >
      {props.options}
    </Select>
  );
};

export default FormikSelect;
