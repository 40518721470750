import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { uploadHistory, getDownloadFileUrl } from "../../redux/project/action";
import { connect } from "react-redux";
import { get } from "lodash";
import "./style.css";
import ErrorPopup from "../shared/ErrorPopup";
import CustomToolTip from "../shared/CustomToolTip";

const UploadHistory = (props) => {
  useEffect(() => {
    props.uploadHistory(props.selectedProject.project_id);
  }, [props.selectedProject.project_id]);

  const [errorData, setErrorData] = useState("");
  const [errorPopupOpen, setErrorPopupOpen] = useState(false);
  const [uploadData, setUploadData] = useState(false);
  const history = props.history;
  const columns = [
    {
      title: "Submitted Data",
      dataIndex: "submitted_date",
      key: "submitted_date",
      defaultSortOrder: "descend",
      sorter: (a, b) =>
        new Date(a.submitted_date).getTime() -
        new Date(b.submitted_date).getTime(),
      render: (submitted_date) => {
        return submitted_date + " UTC";
      },
    },
    {
      title: "Email", //added email column to update history
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Upload Method",
      dataIndex: "upload_method",
      key: "upload_method",
    },
    {
      title: (
        <span>
          File Name
          <CustomToolTip title={`Click the filename to download data from previous submissions. 
          Make sure you copy any data into the most updated Supernova template before you re-submit for any updates!`} />
        </span>
      ),
      dataIndex: "filename",
      key: "filename",
      width: "15%",
      render: (filename, record) => {
        const pattern = /https:\/\/storage\.googleapis\.com\/(.+?)\//; //removing first portion of url
        const trimmedUrl = record.url.replace(pattern, "");
        return (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            onClick={() => {
              props.getDownloadFileUrl(decodeURIComponent(trimmedUrl))
            }}
            target="_blank" rel="noopener noreferrer" >
            {filename}
          </a>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Errors",
      dataIndex: "remark",
      key: "remark",
      render: (remark, record) => {
        return record.status === "success" ? (
          <span className="no-errors">No errors</span>
        ) : record.status === "failed" ? (
          <>
            <a
              className="error-link pointer"
              onClick={() => showUploadErrors(remark, record)}
            >
              View errors
            </a>
          </>
        ) : (
          ""
        );
      },
    },
    {
      title: "Total",
      dataIndex: "file_keyword_count",
      key: "file_keyword_count",
    },
  ];
  const showUploadErrors = (errors, uploadData) => {
    setErrorData(errors);
    setErrorPopupOpen(true);
    setUploadData(uploadData);
  };
  return (
    <div className="upload-history-container">
      {get(props, "selectedProject", {}) ? (
        <div className="upload-history-wrapper">
          <Table
            pagination={{ pageSize: 5, simple: true }}
            rowKey="submitted_date"
            columns={columns}
            dataSource={history}
            loading={props.history_loading}
            locale={{ emptyText: "No history to display" }}
          />
        </div>
      ) : (
        <p className="project-empty-text">Create or select a project</p>
      )}
      <ErrorPopup
        errorData={errorData}
        errorPopupOpen={errorPopupOpen}
        setErrorPopupOpen={setErrorPopupOpen}
        footer={false}
        uploadData={uploadData}
      />
    </div>
  );
};

export default connect(
  (state) => ({
    history_loading: state.Project.history_loading,
    history: state.Project.history,
    selectedProject: state.Dashboard.selectedProject,
  }),
  {
    uploadHistory,
    getDownloadFileUrl
  }
)(UploadHistory);
