const spinner = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100vh"
};

const addSite = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "20vh"
};
const basicStyle = {
  spinner,
  addSite
};

export default basicStyle;
