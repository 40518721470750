import * as ACTION from "../actionTypes";

const initialState = {
  projects: [],
  project_loading: false,
  download_loading: false,
  error: null,
  downloadError: null,
  history: [],
  url: null,
  history_loading: false,
  isManualUploadLoading: false,
  isManualUploadSuccess: false,
  isDownloadComplete: false,
};

export default function projectReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION.LIST_PROJECT_BEGIN:
      return {
        ...state,
        project_loading: true,
        error: null
      };
    case ACTION.LIST_PROJECT_SUCCESS:
      return {
        ...state,
        project_loading: false,
        error: null,
        projects: action.payload
      };
    case ACTION.LIST_PROJECT_ERROR:
      return {
        ...state,
        project_loading: false,
        error: action.error,
        projects: []
      };
    case ACTION.DOWNLOAD_BEGIN:
      return {
        ...state,
        downloadError: null,
        isLoadingDownload: true,
      };
    case ACTION.DOWNLOAD_SUCCESS:
      return {
        ...state,
        downloadError: null,
        isLoadingDownload: false,
      };
    case ACTION.DOWNLOAD_ERROR:
      return {
        ...state,
        downloadError: action.error,
        isLoadingDownload: false,
      };
    case ACTION.UPLOAD_HISTORY_BEGIN:
      return {
        ...state,
        historyError: null,
        history_loading: true
      };
    case ACTION.UPLOAD_HISTORY_SUCCESS:
      return {
        ...state,
        historyError: null,
        history: action.payload,
        history_loading: false
      };
    case ACTION.UPLOAD_HISTORY_ERROR:
      return {
        ...state,
        historyError: action.error,
        history: [],
        history_loading: false
      };
    case ACTION.UPLOAD_URL_BEGIN:
      return {
        ...state
      };
    case ACTION.UPLOAD_URL_SUCCESS:
      return {
        ...state,
        url: action.payload.upload_url
      };
    case ACTION.UPLOAD_URL_ERROR:
      return {
        ...state,
        url: null,
        urlError: action.error
      };
    case ACTION.MANUAL_UPLOAD_BEGIN:
      return {
        ...state,
        isManualUploadLoading: true,
        isManualUploadSuccess: false,
        isFailedManualUpload: false
      };
    case ACTION.MANUAL_UPLOAD_SUCCESS:
      return {
        ...state,
        isManualUploadSuccess: true,
        isManualUploadLoading: false,
        isFailedManualUpload: false
      };
    case ACTION.MANUAL_UPLOAD_SET_FALSE:
      return {
        ...state,
        isManualUploadSuccess: false,
        isManualUploadLoading: false,
        isFailedManualUpload: false
      };
    case ACTION.MANUAL_UPLOAD_ERROR:
      return {
        ...state,
        isManualUploadLoading: false,
        isManualUploadSuccess: false,
        isFailedManualUpload: true
      };
    case ACTION.SET_MANUAL_UPLOAD_FORM_VISIBLE: {
      return {
        ...state,
        isManualUploadSuccess: false,
        isFailedManualUpload: false
      };
    }
    case ACTION.BULK_UPLOAD_BEGIN:
      return {
        ...state,
        isUploading: true,
        isFailedUpload: false,
        isUploadSuccess: false
      };
    case ACTION.BULK_UPLOAD_SUCCESS:
      return {
        ...state,
        isUploadSuccess: true,
        isFailedUpload: false,
        isUploading: false
      };
    case ACTION.BULK_UPLOAD_ERROR:
      return {
        ...state,
        isUploading: false,
        isFailedUpload: true,
        isUploadSuccess: false
      };
    case ACTION.SET_BULK_UPLOAD_FORM_VISIBLE: {
      return {
        ...state,
        isUploadSuccess: false,
        isFailedUpload: false
      };
    }
    default:
      return state;
  }
}
