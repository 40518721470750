import styled from "styled-components";

export const SplashScreenWrapper = styled.div`
  .heading-2 {
    display: flex;
    align-items: center;
    span {
      padding-bottom: 9px;
    }
  }
  .welcome-text {
    font-size: 30px;
    font-weight: 900;
    line-height: 35px;
    color: #000000;
    letter-spacing: 0.6px;
    padding-bottom: 64px;
    font-family: Roboto-Medium;
    margin: 0;
  }
  span,
  h3 {
    font-size: 20px;
    font-weight: 900;
    padding-left: 21px;
    color: #000000;
    line-height: 24px;
  }
  h3{
    padding-left: 0;
    text-align: left;
  }
  p {
    font-size: 14px;
    line-height: 18px;
    padding-left: 57px;
  }

  .spash-screen-wrapper {
    display: flex;
    margin-bottom: 20px;
  }

  .left-pane {
    left: 0;
    section {
      padding-bottom: 87px;
    }
  }
  .right-pane {
    right: 0;
    border-left: 0.3px solid #000000;
    padding-top: 135px !important;
    section {
      padding-bottom: 30px;
    }
  }

  .splash-screen-panes {
    width: 50%;
    padding: 60px;
    .pink-dot {
      width: 10px;
      position: relative;
      right: 65px;
      top: 25px;
    }
  }

  @media only screen and (min-width: 0px) and (max-width: 780px) {
    .heading-2 {
      flex-direction: column;
      align-items: center;
      text-align: center;
      img {
        padding-bottom: 10px;
      }
    }
    .spash-screen-wrapper {
      flex-direction: column;
      align-items: center;
    }
    .pink-dot {
      display: none;
    }
    .splash-screen-panes {
      width: 100%;
      padding: 30px;
      text-align: center;
      section {
        p {
          padding: 0;
          text-align: left;
        }
      }
      h3 {
        padding-left: 0;
        text-align: center;
      }
    }
    .left-pane {
      section {
        padding-bottom: 30px;
      }
    }
    .right-pane {
      border: none;
      padding-top: 0 !important;
      section {
        padding-bottom: 30px;
      }
    }
  }
  @media only screen and (min-width: 1440px) and (max-width: 1499px) {
    .right-pane {
      padding-top: 190px !important;
    }
  }
  @media only screen and (min-width: 1120px) and (max-width: 1439px) {
    .right-pane {
      padding-top: 205px !important;
    }
  }
  @media only screen and (min-width: 960px) and (max-width: 1500px) {
    .right-pane {
      padding-top: 170px !important;
    }
    @media only screen and (min-width: 780px) and (max-width: 1119px) {
      .right-pane {
        padding-top: 205px !important;
      }
    }
  }
`;

export const AppButton = styled.button`
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
  border: 1px solid #000000;
  border-radius: 18px;
  margin-top: 14px;
  padding: 8.5px 20px;
  cursor: pointer;
  img {
    width: 17.62px;
    margin-left: 10px;
  }
  font-family: Roboto-Medium;
  width: fit-content;
`;

export const AppButton2 = styled(AppButton)`
  .arrow-nbsp {
    display: flex;
    margin-left: 4px;
  }
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  @media only screen and (min-width: 0px) and (max-width: 780px) {
    margin: auto;
  }
`;

export const PrimaryButton = styled(AppButton)`
  background-color: #000000;
  color: #ffffff;
  white-space: pre;
  @media only screen and (max-width: 1140px) {
    white-space: normal;
  }
`;

export const PrimaryButton2 = styled(AppButton2)`
  background-color: #000000;
  color: #ffffff;
  @media only screen and (min-width: 0px) and (max-width: 780px) {
    margin-top: 14px;
  }
`;

export const RankTrackingWrapper = styled.div`
  .upload-tabs {
    font-family: Roboto-Medium;
    min-height: 52px;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 15px;
    text-align: center;
    border-bottom: 0.3px solid #000000;
    width: 50%;
    max-width: 1000px !important;

    span {
      color: #000000;
    }
  }

  .MuiTab-textColorInherit.Mui-selected span {
    color: #ffffff;
  }

  .MuiTab-textColorInherit.Mui-selected {
    background-color: #000000;
  }
`;
