import { CircularProgress } from "@material-ui/core";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { getDashboardSlug, getEmbedUrl } from "../../redux/embeddedDashboard/action";
import { IframeWrap } from "./style";

function EmbeddedDashboard(props) {
  const history = useHistory();
  const { pathname, search } = history?.location;

  useEffect(() => {
    props.getUrl(pathname?.split('/').pop(), search.substring(1));
  }, []);

  window.addEventListener("message", function (event) {
    if (event?.source === document.getElementById("embedded-dashboard")?.contentWindow) {
      let response = typeof event?.data == "string" ? JSON.parse(event?.data) : {};
      if (response?.type === "dashboard:loaded")
        props.getSlug(response?.dashboard?.id)
      if (response?.type === "dashboard:filters:changed") {
        let filters = response?.dashboard?.url?.split('?').pop();
        history.push(`?${filters}`);
      }
    }
  });

  // reroute dashboard url with slug
  if (props?.slug !== '' && props?.slug !== pathname?.split('/')[2]) {
    history.push(`/dashboard/${props?.slug}`);
  }

  //replacing '+' with ' '
  const decodeURLString = (urlString) => {
    return decodeURIComponent(urlString?.replace(/\+/g, ' '));
  }

  //converting url with object of key value pairs
  const convertURLToKeyValuePairs = (url) => {
    const pairs = url?.split('&');
    const result = {};
    for (let i = 0; i < pairs?.length; i++) {
      const pair = pairs[i]?.split('=');
      const key = decodeURLString(pair[0]);
      const value = decodeURLString(pair[1]);
      result[key] = value;
    }
    return result;
  }

  // post request to the iframe
  if (search !== "") {
    var my_request = JSON.stringify(
      {
        type: "dashboard:filters:update",
        filters: convertURLToKeyValuePairs(props?.filter),
      }
    );
    var my_iframe = document.getElementById("embedded-dashboard");
    my_iframe?.contentWindow?.postMessage(my_request, props?.url);
  }

  return (
    <IframeWrap>
      {props.url ? (
        <iframe src={props.url} id="embedded-dashboard" title="dashboard"></iframe>
      ) : (
        <span className="iframe-progress-container circular-progress">
          <CircularProgress />
        </span>
      )}
    </IframeWrap>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    getUrl: (pathname, search) => dispatch(getEmbedUrl(pathname, search)),
    getSlug: (pathname) => dispatch(getDashboardSlug(pathname)),
  };
};
const mapStateToProps = (state) => {
  return {
    url: state.EmbeddedDashboard && state.EmbeddedDashboard.iframeUrl,
    slug: state.EmbeddedDashboard && state.EmbeddedDashboard.dashboardSlug,
    filter: state.EmbeddedDashboard && state.EmbeddedDashboard.dashboardFilter,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmbeddedDashboard);
