import React, { useEffect, useState } from "react";
import { CircularProgress } from "@material-ui/core";
import { Button, Collapse, Modal } from "antd";
import AddNewProjectForm from "./AddNewProjectForm";
import { Tag } from 'antd';
import SharePopup from "./SharePopup";
import Divider from "@material-ui/core/Divider";
import "../style.css";
import "./style.css";
import { get } from "lodash";
import { PrimaryButton, PrimaryButton2 } from "../RankTracking/style";

const { Panel } = Collapse;
const MAXLENGTH = 5;

const Projects = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const [sharePopupVisible, setsharePopupVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState(false);
  const [existingUsers, setExistingUsers] = useState([]);
  const [client, setClient] = useState();
  const { project_loading, projects, accessible_clients, filteredClient, filteredProjects, projectFilteredClents, onCloseChip, clearAll } = props;
  const [isFilterApplied, setisFilterApplied] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [viewMore, setViewMore] = useState(false);

  useEffect(() => {
    (filteredClient.length > 0 || filteredProjects.length > 0) ? setisFilterApplied(true) : setisFilterApplied(false);
  }, [filteredClient, filteredProjects]);

  const onCloseFilterCard = (clt, client) => {
    onCloseChip(clt);
    if (client?.length === MAXLENGTH + 1) { setViewMore(false) }
  };

  const concatFilter = filteredClient.concat(projectFilteredClents);

  const getFilteredChip = (prj, concatFilters, fc, fp, view, setView) => {
    const mergedClientProject = fc.concat(fp);
    const renderClients = prj.filter((org) => {
      if (mergedClientProject.includes(org.organization_id)) {
        return true;
      }
      if (mergedClientProject.find(e => { return org.data.find(ele => ele.project_id === e) })) {
        return true;
      }
      return false;
    });
    return (
      <div className={`${fc?.length ? "bottom-border padding-b2r" : ""}`}>

        {(view ? renderClients : renderClients.splice(0, MAXLENGTH)).map((org, index) => {
          return (
            <>
              <Tag key={`tag-${index + 1}`} visible={true} className="filter-tag" closable onClose={() => onCloseFilterCard(org, renderClients)}>{org?.organization}</Tag>
            </>
          );
        })}
        {concatFilters?.length > 0 && !view && renderClients?.length > 0 && (
          <Button type="link" className="clear-button-style" onClick={() => setView(true)}><a>{`+ ${renderClients.length} more`}</a></Button>
        )}
        {concatFilters?.length > 0 && view && renderClients?.length > 0 && (
          <Button type="link" className="clear-button-style" onClick={() => setView(false)}><a>{`show less`}</a></Button>
        )}
        {concatFilters?.length > 0 && (
          <Button type="link" className="clear-button-style" onClick={() => {
            setView(false);
            clearAll();
          }}><a>Clear All</a></Button>
        )}
      </div>
    );
  }

  return (
    <>
      <div className="left-inner-container">
        <div className="projectWrapper">
          {project_loading ? (
            <div className="container-center"><CircularProgress /></div>
          ) : (
            <>
              {getFilteredChip(projects, concatFilter, filteredClient, filteredProjects, viewMore, setViewMore)}
              <div className={filteredClient?.length ? "project-list margin-t2r" : "project-list"}>
                {projects.map((org, index) => {
                  return (
                    (filteredClient
                      .concat(projectFilteredClents)
                      .includes(org.organization_id) ||
                      (projectFilteredClents.length == 0 &&
                        filteredClient.length == 0)) && (
                      <Collapse
                        className={`${!org.status
                            ? "collapse-panel panel-inactive"
                            : "collapse-panel"} collapse-padding`
                        }
                        onChange={(key) => {
                          setSelectedKeys(key);
                          setisFilterApplied(false);
                        }}
                        expandIconPosition={"right"}
                        expandIcon={(props) => (
                          <img
                            className="arrowDownIcon"
                            alt="arrowDownIcon"
                            src={
                              props.isActive
                                ? "/images/Icon_Arrow_Down.png"
                                : "/images/Icon_Arrow_Down.png"
                            }
                          />
                        )}
                        key={org.organization_id}
                        activeKey={
                          isFilterApplied
                            ? filteredClient.concat(projectFilteredClents)
                            : selectedKeys
                        }
                      >
                        <Panel
                          header={`${org.organization} (${org.organization_id}) `}
                          key={org.organization_id}
                          style={{
                            borderBottom:
                              index === projects.length - 1
                                ? "none"
                                : "0.5px solid #7E8486",
                          }}
                        >
                          {/* <div className="share-wrapper" onClick={(event) => {    //Share button from UI is hidden
                        setsharePopupVisible(true);
                        setExistingUsers(org.user_emails);
                        setClient(org);
                        // If you don't want click extra trigger collapse, you can prevent this:
                        event.stopPropagation();
                      }}>
                        <img src="./images/Share_Icon.svg" className="share-icon" alt="share-icon" />
                        <div className="share-label">Share</div>
                      </div> */}

                          {org.data.map((pr) => {
                            return (
                              (filteredProjects.includes(pr.project_id) ||
                                filteredProjects.length === 0 ||
                                !projectFilteredClents.includes(
                                  org.organization_id
                                )) && (
                                <div className="collapse-padding">
                                  <div>
                                    <div
                                      className={
                                        pr.project_id ===
                                          get(
                                            props,
                                            ["selectedProject", "project_id"],
                                            ""
                                          )
                                          ? "selected-project"
                                          : ""
                                      }
                                      onClick={() =>
                                        props.setSelectedProject({
                                          project: pr.project,
                                          project_id: pr.project_id,
                                          organization: org.organization,
                                          organization_id: org.organization_id,
                                          department: pr.department,
                                          domain: pr.domain,
                                          status: org.status,
                                        })
                                      }
                                    >
                                      {`${pr.project}`}
                                    </div>
                                    <img
                                      className="delete-icon"
                                      alt="delete-icon"
                                      style={{
                                        margin: "0 22px",
                                      }}
                                      src="/images/Trash_Icon.svg"
                                      onClick={() => {
                                        setDeleteModalVisible(true);
                                        setSelectedProjectId(pr.project_id);
                                      }}
                                    />
                                  </div>
                                  <Divider />
                                </div>
                              )
                            );
                          })}
                        </Panel>
                      </Collapse>
                    )
                  );
                })}
              </div>
              {!project_loading && projects.length === 0 && (
                <span className="project-create-text">Create a project</span>
              )}
            </>
          )}
        </div>
        <div className="project-modal-footer">
          <PrimaryButton2
            onClick={() => { setOpenModal(true); setFormValues(null) }}
            disabled={
              !(
                accessible_clients.length > 0 ||
                (projects.length > 0 &&
                  projects.some((project) => project.status))
              )
            } //neither have active clients nor part of specific google groups
          >
            Create New
            <div className="arrow-nbsp">
              Project
              <img
                src={"/images/Icon_Arrow-White.png"}
                alt="Black arrow icon"
              />
            </div>
          </PrimaryButton2>
        </div>
      </div>

      <AddNewProjectForm
        openModal={openModal}
        setOpenModal={setOpenModal}
        formValues={formValues}
        setFormValues={setFormValues}
        {...props}
      />
      <SharePopup
        sharePopupVisible={sharePopupVisible}
        setsharePopupVisible={setsharePopupVisible}
        existingUsers={existingUsers}
        setExistingUsers={setExistingUsers}
        client={client}
        {...props}
      />


      <Modal
        className="confirm-modal"
        visible={deleteModalVisible}
        onOk={""}
        onCancel={""}
        footer={null}
        closeIcon={
          <img
            className="closeIcon"
            alt="closeIcon"
            src={"/images/Close-X_Icon.svg"}
            onClick={() => setDeleteModalVisible(false)}
          />
        }
      >
        <div className="confirm-modal-body">
          <img
            className="confirm-icon"
            src="/images/Icon_Are-You-Sure.svg"
            alt="confirm-icon"
          />
          <div className="project-success-message">Are you sure you want to delete this project?</div>
          <div className="confirm-modal-footer">
            <PrimaryButton
              key="submit"
              type="primary"
              onClick={() => {
                props.deleteProject(selectedProjectId);
                setDeleteModalVisible(false);
              }}
            >
              DELETE
              <img
                src={"/images/Icon_Arrow-White.png"}
                alt="Black arrow icon"
              />
            </PrimaryButton>
            <Button
              key="submit"
              type="link"
              onClick={() => setDeleteModalVisible(false)}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Projects;
