import React from "react";
import Signin from "./signin/Signin";
import firebase from 'firebase/app'
import 'firebase/auth';
import { config } from "./helpers/config";
import { connect } from "react-redux";
import "./App.css";
import Home from "./components/Home/index"

firebase.initializeApp(config);

const App = props => {
  const { isLoggedIn } = props;
  return (
    <div>
      {isLoggedIn ? (
        <Home firebase={firebase} isLoggedIn={true}></Home>
      ) : (
        <Signin firebase={firebase} {...props} />
      )}
    </div>
  );
};

// connect to store
export default connect(
  state => ({
    isLoggedIn: localStorage.getItem("access_token") ? true : false
  }),
  {}
)(App);
