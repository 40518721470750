import * as ACTION from '../actionTypes';

const initialState = {
	loading: false,
};

export default function authResucer(state = initialState, action) {
	switch (action.type) {
		case ACTION.AUTH_BEGIN:
			return {
				...state,
				loading: true,
			};
		case ACTION.AUTH_SUCCESS:
			return {
				...state,
				loading: false,
			};
		case ACTION.AUTH_ERROR:
			return {
				...state,
				loading: false,
			};
		default:
			return state;
	}
}
