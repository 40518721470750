import styled from "styled-components";
import { Grid }  from '@material-ui/core';

export const Container = styled.div`
`;

export const HeaderSection = styled.div`
  z-index: 100 !important;
  ${(props) =>
    props.isloggedin
      ? 'background-image: url("/images/Header-background.png")'
      : "background-color: #fff"};
  height: 5rem;
  display: flex;
  position: fixed;
  top: 0;
  width: 100%;
  align-items: center;
  padding: 0rem 5rem;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
  justify-content: space-between;
  font-family: Roboto-Regular !important;
  background-size: cover;

  @media only screen and (max-width: 1200px) {
    flex-direction: column;
    height: auto;
    padding: 9px 0 0 0;
    .tab-wrapper {
      padding-top: 15px;
      align-self: center;
    }

    .right-header-wrapper {
      flex-direction: column;
      width: 100%;
      align-items: flex-end;
    }
  }

  @media only screen and (min-width: 1200px) {
    .tab-wrapper {
      align-self: flex-end;
    }
  }

  .bug-submission {
    border: none;
    padding: 0.5rem 2rem 0.5rem 0.5rem;
    background: transparent;
    cursor: pointer;
    color: #000000;
    font-size: 12px;
    line-height: 14px;
    font-family: "Roboto-Regular";
  }

  .tabs {
    font-family: Roboto-Medium;
    min-height: 52px;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 15px;
    text-align: center;
    border: 0.3px solid #000000;
    span {
      color: #000000;
    }
  }

  .mailIcon {
    height: 9.52px;
    width: 12px;
  }

  .logoutIcon {
    height: 9px;
    width: 9px;
  }

  .logout {
    padding-left: 0.5rem;
    color: #000000;
    font-size: 12px;
    line-height: 14px;
    font-family: "Roboto-Regular";
  }

  .MuiTab-textColorInherit.Mui-selected span {
    color: #ffffff;
  }

  .MuiTab-wrapper {
    color: #3a4245;
  }

  .right-header-wrapper {
    display: flex;
  }

  .uat-logo {
    color: #434343;
    font-size: 13.5px;
    font-weight: bold;
  }

  span {
    display: flex;
    flex-direction: column;
    span {
      padding-left: 75px;
    }
  }
  img {
    height: 20px;
  }

  .MuiTab-textColorInherit.Mui-selected {
    background-color: #000000;
  }
`;

export const Button = styled.button`
  border: none;
  padding: 0.5rem 2rem;
  background: transparent;
  cursor: pointer;
  color: #434343;
`;

export const HeaderContainer = styled(Grid)`
  z-index: 100 !important;
  ${(props) =>
    props.isloggedin
      ? 'background-image: url("/images/Header-background.png")'
      : "background-color: #fff"};
  min-height: 5rem;
  font-family: Roboto-Regular !important;
  background-size: cover;

  .logo-container {
    padding: 0 0 0 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .tab-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .action-button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: end;
    padding-right: 40px;

  }

  .uat-logo {
    color: #434343;
    font-size: 13.5px;
    font-weight: bold;
  }

  .MuiTab-textColorInherit.Mui-selected span {
    color: #ffffff;
  }

  .MuiTab-wrapper {
    color: #3a4245;
  }

  .MuiTab-textColorInherit.Mui-selected {
    background-color: #000000;
  }

  .tabs {
    font-family: Roboto-Medium;
    min-height: 52px;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 15px;
    text-align: center;
    border: 1px solid #434343;
    span {
      color: #000000;
    }
  }

  .bug-submission {
    border: none;
    padding: 0.5rem 2rem 0.5rem 0.5rem;
    background: transparent;
    cursor: pointer;
    color: #000000;
    font-size: 12px;
    line-height: 14px;
    font-family: "Roboto-Regular";
  }

  .right-header-wrapper {
    display: flex;
  }

  .mailIcon {
    height: 9.52px;
    width: 12px;
  }

  .logoutIcon {
    height: 9px;
    width: 9px;
  }

  .logout {
    padding-left: 0.5rem;
    color: #000000;
    font-size: 12px;
    line-height: 14px;
    font-family: "Roboto-Regular";
  }


  @media only screen and (max-width: 600px) {
    .tab-wrapper {
      align-self: center;
    }
    .action-button-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: end;
      padding-right: 20px;
    }
  }

  @media only screen and (max-width: 1200px) {
    .tab-wrapper {
      align-self: center;
    }
    .action-button-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: end;
      padding-right: 20px;
    }
  }


`;
