import React from "react";
import {
  SS_RANK_TRACK,
  SS_RANK_TRACK_DASHBOARD,
  SS_SERP_FEATURE,
  SS_TRACK_ONE_TIME,
  SS_ORGANIC_RANK_DATA,
  SS_CLIENT_SPECIFIC_DATASET,
} from "../../helpers/redirectionLinks";
import { AppButton2, SplashScreenWrapper } from "./style";

const SplashScreen = () => {
  const redirect = (url) => {
    window.open(url, "_blank");
  };
  return (
    <SplashScreenWrapper>
      <div className="box-wrapper spash-screen-wrapper right-bottom-wrapper">
        <div className="splash-screen-panes left-pane">
          <h3 className="welcome-text">Welcome to SERP Tracking</h3>

          <section>
            <div className="heading-2">
              <img
                src={"/images/When-you-want.png"}
                alt="When you want icon"
                style={{ width: 34.59 }}
              />
              <span>Rankings when you want them</span>
            </div>
            <p>
              Closely monitor your most important search terms daily or weekly
              and keep an eye on other search terms by scheduling them monthly,
              quarterly, or even annually.
            </p>
            <aside>
              <p>
                Upload a large-scale set of search terms (up to a million at a
                time—you’re only limited by the max rows in a CSV!) to
                track one-time for a snapshot of data
                to build deep dive analyses.
              </p>
            </aside>
          </section>

          <section>
            <div className="heading-2">
              <img
                src={"/images/SERP-feature-insight.png"}
                alt="SERP feature insight icon"
                style={{ width: 35 }}
              />
              <span>Rich SERP feature insights</span>
            </div>
            <p>
              We won’t just tell you where you’re ranking—we’ll tell you which
              SERP features appeared, who owned them, and details like organic
              metadata, PAA questions & answers, Google Business Profiles
              information, schema, and more.
            </p>
            <aside>
              <p>
                With hundreds of data points for each result, you can mine the
                SERPs for content and customer insights.
              </p>
              <AppButton2
                onClick={() => redirect(SS_SERP_FEATURE)}
                className="margin-appbutton-ss bg-color"
              >
                Learn more about SERP
                <div className="arrow-nbsp">
                  features
                  <img src={"/images/Black-Arrow.png"} alt="Black arrow icon" />
                </div>
              </AppButton2>
            </aside>
          </section>
        </div>

        <div className="splash-screen-panes right-pane">
          <section>
            <img
              src={"/images/Pink-Dot.png"}
              alt="pink dot icon"
              className="pink-dot"
            />
            <div className="heading-2">
              <img
                src={"/images/Automated-monthly-tracking.png"}
                alt="Automated monthly tracking icon"
                style={{ width: 26 }}
              />
              <span>Flexible tracking that scales</span>
            </div>

            <p>
              Create as many projects as you want for your clients to monitor
              multiple websites, get the best rank for specific subdomains or
              subfolders, or even track “as a competitor”.
            </p>
            <aside>
              <p>
                Get rankings across multiple search engines, device
                combinations, and thousands of languages and locations from the
                country to the zip code level.
              </p>
              <AppButton2
                onClick={() => redirect(SS_RANK_TRACK)}
                className="margin-appbutton-ss bg-color"
              >
                Learn about SERP
                <div className="arrow-nbsp">
                  tracking
                  <img src={"/images/Black-Arrow.png"} alt="back-arrow"/>
                </div>
              </AppButton2>
            </aside>
          </section>
          <section>
            <img
              src={"/images/Pink-Dot.png"}
              alt="pink dot icon"
              className="pink-dot"
            />
            <div className="heading-2">
              <img
                src={"/images/Search-insights.png"}
                alt="Search insights icon"
                style={{ width: 34 }}
              />
              <span>Analyze anywhere</span>
            </div>
            <p>
              Get instant insights from SERP Tracking Dashboards in SeerSignals, where you can set up alerts and scheduled reports to
              email or Slack.
            </p>
            <aside>
              <p>
                Connect to curated reports using SeerSignals Connectors from your favorite data visualization tools to further transform
                and join other data for custom analyses.
              </p>
            </aside>
            {/* <aside>
              <p>
                Explore the{" "}
                <a onClick={() => redirect(SS_ORGANIC_RANK_DATA)}>
                  {" "}
                  Organic Rankings data lake{" "}
                </a>{" "}
                to answer questions and analyze historical data across clients,
                without SQL.
              </p>
            </aside> */}
          </section>
        </div>
      </div>
    </SplashScreenWrapper>
  );
};

export default SplashScreen;
