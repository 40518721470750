import React, { useState, useEffect } from "react";
import ManualUploadForm from "./ManualUploadForm";
import BulkUpload from "./BulkUpload";
import { connect } from "react-redux";
import {
  saveTags
} from "../../redux/rankTracking/action";
import {
  templateDownload,
  getUrl,
  manualUpload,
  download,
  setManualUploadFormVisible,
  setBulkUploadFormVisible,
  bulkUpload,
  dispatchManualUploadSetFalse
} from "../../redux/project/action";
import "./style.css";
import { Radio } from "antd";

const Upload = props => {
  const [selectedTab, setSelectedTab] = useState("manual");

  useEffect(() => {
    if (props.selectedProject) {
      props.fetchTags(props.selectedProject.project_id);
    }
  }, [props.selectedProject, props.token]);

  return (
    <>
      {props.selectedProject ? props.selectedProject?.status ? (
        <> <div className="searchTermHeading">Select upload type:</div>
          <Radio.Group defaultValue="manual" buttonStyle="solid" className="Upload-toggle-btn" size="medium" >
            <Radio.Button value="manual" onClick={() => {
              setSelectedTab("manual");
              props.setManualUploadFormVisible();
            }}>Manual</Radio.Button>
            <Radio.Button value="bulk" onClick={() => {
              setSelectedTab("bulk");
              props.setBulkUploadFormVisible();
            }}>Bulk</Radio.Button>
          </Radio.Group>
          {selectedTab === "manual" ? (
            <ManualUploadForm {...props} />
          ) : (
            <BulkUpload {...props} />
          )}
        </>
      ) : (
        <p className="project-empty-text">This organization is not an active Seer client. Use the SeerInteractive organization if you are uploading search terms for BD purposes.</p>
      ) : (
        <p className="project-empty-text">Create or select a project</p>
      )}
    </>
  );
};

export default connect(
  state => ({
    signUrl: state.Project.url,
    selectedProject: state.Dashboard.selectedProject,
    isLoadingPage:
      state.Dashboard.isFrequencyLoading ||
      state.Dashboard.isEngineLoading ||
      state.Dashboard.isTagsLoading ||
      state.Dashboard.isDeviceLoading,
    isLoading: state.Project.isManualUploadLoading,
    isManualUploadSuccess: state.Project.isManualUploadSuccess,
    isFailedManualUpload: state.Project.isFailedManualUpload,
    isUploading: state.Project.isUploading,
    isFailedUpload: state.Project.isFailedUpload,
    isUploadSuccess: state.Project.isUploadSuccess,
    token: localStorage.getItem("access_token"),
    isLoadingDownload: state.Project.isLoadingDownload,
    downloadError: state.Project.downloadError
  }),
  {
    templateDownload,
    getUrl,
    manualUpload,
    saveTags,
    download,
    setManualUploadFormVisible,
    setBulkUploadFormVisible,
    bulkUpload,
    dispatchManualUploadSetFalse
  }
)(Upload);
