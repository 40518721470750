import { getAccessToken } from './helpers/utility';

export function onRequest(config, superNovaRequest) {
  let accessToken = getAccessToken();
  config.headers = {
    ...config.headers,
    Authorization: superNovaRequest ? `Bearer ${accessToken}` : `bearer ${accessToken}`
  };
  return config;
}

export function onRequestError(error) {
  return Promise.reject(error);
}
