import React, { useState, useEffect } from "react";
import { Tabs, Tab, Hidden } from "@material-ui/core";
import { clearAll } from "../../helpers/utility";
import logo from "../../assets/logo.png";
import { HeaderContainer, Button, Container } from "./style";
import TabPanel from "../shared/TabPanel";
import RankTracking from "../RankTracking/component";
import EmbeddedDashboard from "../EmbeddedDashboard";
import { Alert } from "antd";
import { RESOURCES, SEARCH_DISCOVERY } from "../../helpers/redirectionLinks";
import AppBar from '@material-ui/core/AppBar';
import { Grid } from '@material-ui/core';
import { setSelectedProject } from "../../redux/rankTracking/action";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

const Home = ({ isLoggedIn, firebase }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [filteredClient, setFilteredClient] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = history?.location;

  useEffect(() => {
    if(pathname === "/rank-tracking") { //bookmark rank-tracking tab url
      history.push('/track-search-terms');
      setSelectedTab(1);
    }
    if(pathname === "/track-search-terms") {
      setSelectedTab(1);
    }
    clearCacheData();
  }, [])

  const handleLogout = () => {
    clearAll();
    firebase.auth().signOut();
    history.push('/');
    window.location.reload();
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleResourceTabClick = () => {
    setSelectedTab(0);        //The first tab is selected and resource url opened on new window
    window.open(
      RESOURCES,
      "_blank"
    );
  }

  const handleSearchDiscoveryTabClick = () => {
    setSelectedTab(0);        //The first tab is selected and search discovery url opened on new window
    window.open(
      SEARCH_DISCOVERY,
      "_blank"
    );
  }

  const clearAllFilter = () => {
    dispatch(setSelectedProject(null));
    setFilteredClient([]);
    setFilteredProjects([]);
  }

  // Function to clear complete cache data
  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  };

  const navigateBack = () => {
    history.push('/');
    clearCacheData();
    window.location.reload(true);
  }

  const navigateHelp = () => {
    const helpURL = '/help';
    window.open(helpURL, '_blank');
  }

  const actionBox = (
    <Grid item xs={12} sm={6} md={12} lg={3} xl={3} className="action-button-container">
      <div className="right-header-wrapper">
        <div>
          {
            isLoggedIn && (
              <>
                <img
                  className="mailIcon"
                  alt="mailIcon"
                  src={"/images/Support-Bug-Submission_Icon.png"}
                />
                <button className="bug-submission" onClick={navigateHelp}>Get Help</button>
              </>
            )
          }
        </div>
        <div>
          {isLoggedIn && (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a className="header-icons" onClick={() => handleLogout()}>
              <img
                className="logoutIcon"
                alt="logoutIcon"
                src={"/images/Login-logout_icon.png"}
              />
              <Button className="logout">Logout</Button>
            </a>
          )}
        </div>
      </div>
    </Grid>
  );

  return (
    <Container>
      <AppBar position="static" style={{ borderBottom: "0.3px solid rgb(173 161 161)", boxShadow: 'none' }}>
        <Grid container>
          {process.env.REACT_APP_ENVIRONMENT &&
            process.env.REACT_APP_ENVIRONMENT === "uat" && (
              <Grid item xs={12}>
                <Alert
                  showIcon={false}
                  className="uat-banner"
                  message={
                    <div>
                      <img
                        className="uat-alert-icon"
                        src="/images/UAT-Alert-Icon.png"
                        alt="UAT-Alert-Icon"
                      />
                      Heads up! You’re viewing the Supernova UAT site for
                      testing.&nbsp;
                      <a href="https://supernova.seerinteractive.com/">
                        Click here
                      </a>
                      &nbsp;to head over to the Production site.
                    </div>
                  }
                  banner
                />
              </Grid>
            )}
          <Grid item xs={12}>
            <HeaderContainer container isloggedin={isLoggedIn}>
              <Grid item xs={12} sm={6} md={4} lg={3} xl={3} className="logo-container">
                <img src={logo} alt="logo" style={{ width: '65%', cursor: 'pointer' }} onClick={() => {
                  navigateBack();
                }} />
                {process.env.REACT_APP_ENVIRONMENT === "uat" && (
                  <span className="uat-logo">SUPERNOVA UAT</span>
                )}
              </Grid>
              <Hidden only={["md", "lg", "xl"]}>
                {actionBox}
              </Hidden>
              <Grid item xs={12} sm={12} md={5} lg={6} xl={6} className="tab-container">
                {isLoggedIn && (
                  <Tabs
                    value={selectedTab}
                    onChange={handleTabChange}
                    className="tab-wrapper"
                    TabIndicatorProps={{
                      style: {
                        background: "none",
                      },
                    }}
                  >
                    <Tab label="Dashboards" className="tabs font-bold-700" onClick={() => navigateBack()} />
                    <Tab label="Track Search Terms" className="tabs font-bold-700" onClick={() => {
                      history.push('/track-search-terms');
                      clearAllFilter();
                    }} />
                    {/* <Tab
                      label="Search Discovery"
                      className="tabs font-bold-700"
                      onClick={handleSearchDiscoveryTabClick}
                    /> */}
                    <Tab
                      label="Help Center"
                      className="tabs font-bold-700"
                      onClick={handleResourceTabClick}
                    />
                  </Tabs>
                )}
              </Grid>
              <Hidden only={["sm", "xs"]}>
                {actionBox}
              </Hidden>
            </HeaderContainer>
          </Grid>
        </Grid>
      </AppBar>
      {isLoggedIn && (
        <div className="fixed-container">
          <TabPanel value={selectedTab} index={0}>
            <EmbeddedDashboard />
          </TabPanel>
          <TabPanel value={selectedTab} index={1}>
            <RankTracking firebase={firebase} filteredClient={filteredClient} setFilteredClient={setFilteredClient}
              filteredProjects={filteredProjects} setFilteredProjects={setFilteredProjects} clearAll={clearAllFilter}
            />
          </TabPanel>
        </div>
      )}
    </Container>
  );
};

export default Home;
