import React from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { Spin } from "antd";
import { clearAll } from "../helpers/utility";
import { connect } from "react-redux";
import { auth } from "../redux/Auth/action";
import basicStyle from "../helpers/basicStyle";
import "./Signin.css";
import Home from "../components/Home"

const { spinner } = basicStyle;

class Signin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      loginError: null
    };
  }
  uiConfig = {
    signInFlow: "popup",
    signInOptions: [this.props.firebase.auth.GoogleAuthProvider.PROVIDER_ID],
    callbacks: {
      signInSuccessWithAuthResult: () => {
        const { firebase } = this.props;
        const firebaseAccessToken = JSON.parse(JSON.stringify(firebase.auth().currentUser)).stsTokenManager.accessToken;
        if (
          firebase.auth().currentUser.email.includes("gritstone.ai") ||
          firebase.auth().currentUser.email.includes("seerinteractive.com")
        ) {
          localStorage.setItem(
            "firebase_token",
            firebaseAccessToken
              // ? firebase.auth().currentUser.ra
              // : firebase.auth().currentUser.ma
          );
          localStorage.setItem("user_email", firebase.auth().currentUser.email);
          this.props.auth({
            errorCallback: () => {
              this.handleErrorCallback();
              firebase.auth().signOut();
            },
          });
          sessionStorage.setItem("loginError", "");
        } else {          
            this.handleErrorCallback();
            firebase.auth().signOut();
        }
      },
    },    
  };

  componentDidMount() {
    this.authListener();
  }

  componentDidUpdate(prevProps) {
    sessionStorage.getItem("loginError") === "true" &&
      !this.state.loginError &&
      this.setState({ loginError: "Reach out to ethanl@seerinteractive.com for access" })
  }

  handleErrorCallback(){
    sessionStorage.setItem("loginError", "true")
    clearAll();    
    window.location.reload();
  }

  authListener() {
    this.props.firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.setState({ user });
      } else {
        this.setState({ user: null });
      }
    });
  }
  render() {
    const { loading } = this.props;
    if (loading) {
      return (
        <div style={spinner}>
          <Spin size="large" />
        </div>
      );
    }

    return (
      <div className="signin-container">
        <Home isLoggedIn={false} />
        <div className="signin-body">
          <div className="firebase-wrapper">
            <div className="app-name">Supernova</div>
            <StyledFirebaseAuth
              uiCallback={(ui) => ui.disableAutoSignIn()}
              uiConfig={this.uiConfig}
              firebaseAuth={this.props.firebase.auth()}
            />
            <div className="login-error">{this.state.loginError}</div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    auth: (firebase) => dispatch(auth(firebase)),
  };
};
const mapStateToProps = (state) => {
  return {
    isLoggedIn: localStorage.getItem("access_token") ? true : false,
    loading: state.Auth.loading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Signin);
