export const FETCH_NOTIFICATION_BEGIN = "FETCH_NOTIFICATION_BEGIN";
export const FETCH_NOTIFICATION_SUCCESS = "FETCH_NOTIFICATION_SUCCESS";
export const FETCH_NOIFICATION_ERROR = "FETCH_NOIFICATION_ERROR";
export const FETCH_METADATA_BEGIN = "FETCH_METADATA_BEGIN";
export const FETCH_METADATA_SUCCESS = "FETCH_METADATA_SUCCESS";
export const FETCH_METADATA_ERROR = "FETCH_METADATA_ERROR";
export const FETCH_ORGANIZATION_BEGIN = "FETCH_ORGANIZATION_BEGIN";
export const FETCH_ORGANIZATION_SUCCESS = "FETCH_ORGANIZATION_SUCCESS";
export const FETCH_ORGANIZATION_ERROR = "FETCH_ORGANIZATION_ERROR";
export const FETCH_DEPARTMENT_BEGIN = "FETCH_DEPARTMENT_BEGIN";
export const FETCH_DEPARTMENT_SUCCESS = "FETCH_DEPARTMENT_SUCCESS";
export const FETCH_DEPARTMENT_ERROR = "FETCH_DEPARTMENT_ERROR";
export const FETCH_PROJECT_BEGIN = "FETCH_PROJECT_BEGIN";
export const FETCH_PROJECT_SUCCESS = "FETCH_PROJECT_SUCCESS";
export const FETCH_PROJECT_ERROR = "FETCH_PROJECT_BEGIN";
export const SAVE_PROJECT_BEGIN = "SAVE_PROJECT_BEGIN";
export const SAVE_PROJECT_SUCCESS = "SAVE_PROJECT_SUCCESS";
export const SAVE_PROJECT_ERROR = "SAVE_PROJECT_ERROR";
export const SHARE_BEGIN = "SHARE_BEGIN";
export const SHARE_SUCCESS = "SHARE_SUCCESS";
export const SHARE_ERROR = "SHARE_ERROR";
export const DELETE_BEGIN = "DELETE_BEGIN";
export const DELETE_SUCCESS = "DELETE_SUCCESS";
export const DELETE_ERROR = "DELETE_ERROR";
export const LIST_PROJECT_BEGIN = "LIST_PROJECT_BEGIN";
export const LIST_PROJECT_SUCCESS = "LIST_PROJECT_SUCCESS";
export const LIST_PROJECT_ERROR = "LIST_PROJECT_ERROR";
export const TEMPLATE_DOWNLOAD_BEGIN = "TEMPLATE_DOWNLOAD_BEGIN";
export const TEMPLATE_DOWNLOAD_SUCCESS = "TEMPLATE_DOWNLOAD_SUCCESS";
export const TEMPLATE_DOWNLOAD_ERROR = "TEMPLATE_DOWNLOAD_ERROR";
export const DOWNLOAD_BEGIN = "DOWNLOAD_BEGIN";
export const DOWNLOAD_SUCCESS = "DOWNLOAD_SUCCESS";
export const DOWNLOAD_ERROR = "DOWNLOAD_ERROR";
export const UPLOAD_HISTORY_BEGIN = "UPLOAD_HISTORY_BEGIN";
export const UPLOAD_HISTORY_SUCCESS = "UPLOAD_HISTORY_SUCCESS";
export const UPLOAD_HISTORY_ERROR = "UPLOAD_HISTORY_ERROR";
export const UPLOAD_URL_BEGIN = "UPLOAD_URL_BEGIN";
export const UPLOAD_URL_SUCCESS = "UPLOAD_URL_SUCCESS";
export const UPLOAD_URL_ERROR = "UPLOAD_URL_ERROR";
export const UPLOAD_BEGIN = "UPLOAD_BEGIN";
export const UPLOAD_SUCCESS = "UPLOAD_SUCCESS";
export const UPLOAD_ERROR = "UPLOAD_ERROR";
export const FETCH_CACHED_ORG = "FETCH_CACHED_ORG";
export const FETCH_CACHED_DEPT = "FETCH_CACHED_DEPT";
export const AUTH_BEGIN = "AUTH_BEGIN";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_ERROR = "AUTH_ERROR";
export const DELETE_PROJECT_BEGIN = "DELETE_PROJECT_BEGIN";
export const DELETE_PROJECT_SUCCESS = "DELETE_PROJECT_SUCCESS";
export const DELETE_PROJECT_ERROR = "DELETE_PROJECT_ERROR";
export const FETCH_FREQUENCY_BEGIN = "FETCH_FREQUENCY_BEGIN";
export const FETCH_FREQUENCY_SUCCESS = "FETCH_FREQUENCY_SUCCESS";
export const FETCH_FREQUENCY_ERROR = "FETCH_FREQUENCY_ERROR";
export const FETCH_ENGINE_BEGIN = "FETCH_ENGINE_BEGIN";
export const FETCH_ENGINE_SUCCESS = "FETCH_ENGINE_SUCCESS";
export const FETCH_ENGINE_ERROR = "FETCH_ENGINE_ERROR";
export const FETCH_LANG_BEGIN = "FETCH_LANG_BEGIN";
export const FETCH_LANG_SUCCESS = "FETCH_LANG_SUCCESS";
export const FETCH_LANG_ERROR = "FETCH_LANG_ERROR";
export const FETCH_TAGS_BEGIN = "FETCH_TAGS_BEGIN";
export const FETCH_TAGS_SUCCESS = "FETCH_TAGS_SUCCESS";
export const FETCH_TAGS_ERROR = "FETCH_TAGS_ERROR";
export const SET_SELECTED_PROJECT = "SET_SELECTED_PROJECT";
export const MANUAL_UPLOAD_BEGIN = "MANUAL_UPLOAD_BEGIN";
export const MANUAL_UPLOAD_SET_FALSE = "MANUAL_UPLOAD_SET_FALSE";
export const MANUAL_UPLOAD_SUCCESS = "MANUAL_UPLOAD_SUCCESS";
export const MANUAL_UPLOAD_ERROR = "MANUAL_UPLOAD_ERROR";
export const BULK_UPLOAD_BEGIN = "BULK_UPLOAD_BEGIN";
export const BULK_UPLOAD_SUCCESS = "BULK_UPLOAD_SUCCESS";
export const BULK_UPLOAD_ERROR = "BULK_UPLOAD_ERROR";
export const SAVE_TAGS_BEGIN = "SAVE_TAGS_BEGIN";
export const SAVE_TAGS_SUCCESS = "SAVE_TAGS_SUCCESS";
export const SAVE_TAGS_ERROR = "SAVE_TAGS_ERROR";
export const SET_MANUAL_UPLOAD_FORM_VISIBLE = "SET_MANUAL_UPLOAD_FORM_VISIBLE";
export const SET_BULK_UPLOAD_FORM_VISIBLE = "SET_BULK_UPLOAD_FORM_VISIBLE";
export const READ_NOTIFICATION_SUCCESS = "READ_NOTIFICATION_SUCCESS";
export const READ_NOTIFICATION_ERROR = "READ_NOTIFICATION_ERROR";
export const READ_NOTIFICATION_BEGIN = "READ_NOTIFICATION_BEGIN";
export const CLEAR_NOTFN_BEGIN = "CLEAR_NOTFN_BEGIN";
export const CLEAR_NOTFN_SUCCESS = "CLEAR_NOTFN_SUCCESS";
export const CLEAR_NOTFN_ERROR = "CLEAR_NOTFN_ERROR";
export const FETCH_DEVICE_BEGIN = "FETCH_DEVICE_BEGIN";
export const FETCH_DEVICE_ERROR = "FETCH_DEVICE_ERROR";
export const FETCH_DEVICE_SUCCESS = "FETCH_DEVICE_SUCCESS";
export const FETCH_EMBED_URL_BEGIN = "FETCH_EMBED_URL_BEGIN";
export const FETCH_EMBED_URL_SUCCESS = "FETCH_EMBED_URL_SUCCESS";
export const FETCH_EMBED_URL_ERROR = "FETCH_EMBED_URL_ERROR";
export const FETCH_ACCESSIBLE_CLIENTS_BEGIN = "FETCH_ACCESSIBLE_CLIENTS_BEGIN";
export const FETCH_ACCESSIBLE_CLIENTS_SUCCESS = "FETCH_ACCESSIBLE_CLIENTS_SUCCESS";
export const FETCH_ACCESSIBLE_CLIENTS_ERROR = "FETCH_ACCESSIBLE_CLIENTS_ERROR";
export const FETCH_DASHBOARD_SLUG_BEGIN = "FETCH_DASHBOARD_SLUG_BEGIN";
export const FETCH_DASHBOARD_SLUG_SUCCESS = "FETCH_DASHBOARD_SLUG_SUCCESS";
export const FETCH_DASHBOARD_SLUG_ERROR = "FETCH_DASHBOARD_SLUG_ERROR";
export const FETCH_DOWNLOAD_FILE_BEGIN = "FETCH_DOWNLOAD_FILE_BEGIN";
export const FETCH_DOWNLOAD_FILE_SUCCESS = "FETCH_DOWNLOAD_FILE_SUCCESS";
export const FETCH_DOWNLOAD_FILE_ERROR = "FETCH_DOWNLOAD_FILE_ERROR";
export const FETCH_COUNTRY_BEGIN = "FETCH_COUNTRY_BEGIN";
export const FETCH_COUNTRY_SUCCESS = "FETCH_COUNTRY_SUCCESS";
export const FETCH_COUNTRY_ERROR = "FETCH_COUNTRY_ERROR";
export const FETCH_LOCATION_BEGIN = "FETCH_LOCATION_BEGIN";
export const FETCH_LOCATION_SUCCESS = "FETCH_LOCATION_SUCCESS";
export const FETCH_LOCATION_ERROR = "FETCH_LOCATION_ERROR";
export const RESET_LOCATION = "RESET_LOCATION";
export const FETCH_LOCATION_TYPE_BEGIN = "FETCH_LOCATION_TYPE_BEGIN";
export const FETCH_LOCATION_TYPE_SUCCESS = "FETCH_LOCATION_TYPE_SUCCESS";
export const FETCH_LOCATION_TYPE_ERROR = "FETCH_LOCATION_TYPE_ERROR";
export const RESET_LOCATION_TYPE = "RESET_LOCATION_TYPE";
