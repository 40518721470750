import React from "react";
import { Modal } from "antd";
import "../../components/style.css";
import { PrimaryButton } from "./style";

const ErrorPopup = (props) => {
  let errorString = props?.errorData?.split("\n").map(Function.prototype.call, String.prototype.trim);
  let validDataString = props?.validData && props?.validData?.split(",").map(Function.prototype.call, String.prototype.trim);

  return (
    <div>
      <Modal
        title=""
        visible={props.errorPopupOpen}
        onOk={() => {
          props.setErrorPopupOpen(false);
          props.alterIngnoreErrors();
        }}
        onCancel={() => props.setErrorPopupOpen(false)}
        okText={props.okText}
        cancelText={props.cancelText}
        cancelButtonProps={!props.footer ? { style: { display: "none" } } : ""}
        okButtonProps={!props.footer ? { style: { display: "none" } } : ""}
        className="error-popup"
        closeIcon={(
          <img
            className="closeIcon"
            alt="closeIcon"
            src={"/images/Close-X_Icon.svg"}
          />
        )}
      >
        <h5 className="bottom-title">Upload failed with errors</h5>
        {props?.uploadData &&
          <>
            <div className="error-popup-upload-data">
              <div><span className="upload-data-label">Submitted data</span>
                <span className="upload-data-value">{props?.uploadData?.submitted_date} UTC</span>
              </div>
              {props?.uploadData?.filename && <div>
                <span className="upload-data-label">File Name</span>
                <span className="upload-data-value">{props?.uploadData?.filename}</span>
              </div>}
              <div><span className="upload-data-label">Upload method</span>
                <span className="upload-data-value">{props?.uploadData?.upload_method}</span></div>
            </div>
            <div className="error-popup-error-container">
              <span className="error-popup-error-label"> ERRORS</span>
              <span className="error-popup-errors">{errorString.map((error) =>
                <> {error}<br /></>
              )}</span>
            </div>
            <PrimaryButton
              onClick={() =>
                props.setErrorPopupOpen(false)
              }
            >
              CLOSE
              <img src={"/images/Icon_Arrow-White.png"} alt="Black arrow icon" />
            </PrimaryButton>
          </>}

        {props.footer && <>
          <div className="error-popup-error-container error-popup-upload-data">
            <span className="error-popup-error-label"> KEYWORDS</span>
            <span className="error-popup-errors">{errorString.map((error,index) =>
              <> {index >0 && <br />}{error} </>
            )}
           {validDataString && <span style={{color:'#3a4245'}}> {validDataString.map((data) =>
              <> {data} <br /> </>
            )}</span>}
            </span>
          </div>
          <div className="error-footer">
            <p className="validation-text">
              *Use comma or enter key to add a new keyword
            </p>
            <p className="validation-text">
              *Only alphanumeric values allowed
            </p>
            <p className="validation-text">
              *Remove empty elements whitespaces and trailing commas
            </p>
          </div>
        </>}
      </Modal>
    </div>
  );
};

export default ErrorPopup;
