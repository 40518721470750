import React from "react";
import { Modal } from "antd";
import "./style.css";
import Moment from "react-moment";
import { CircularProgress } from "@material-ui/core";

const Notification = props => {
  const handleCancel = () => {
    props.setNotificationModalOpen(false);
  };
  return (
    <Modal
      className="notification-modal"
      // maskStyle={{ backgroundColor: "#3A4245", opacity: 0.8 }}
      title={
        <div>
          <div className="title-notifications">Notifications</div>
        </div>
      }
      visible={props.notificationModalOpen}
      onCancel={handleCancel}
      onOk={handleCancel}
      closeIcon={(
        <img
          className="closeIcon"
          alt="closeIcon"
          src={"/images/Close-X_Icon.svg"}
        />
      )}
      footer={[<div className="clear-btn" onClick={() => props.clearAllNotifications(props.notification)} >
        Clear all
      </div>]}
    >
      {props.isClearNotificationLoading ? (
        <div className="circular-progress">
          <CircularProgress />
        </div>
      ) : (
        <>

          {props.notification.length === 0 && <div className="notification empty-notifications-wrapper">
            <img
              className="notification-icon"
              alt="notification-icon"
              src={"/images/Notification-Gray_Icon.svg"}
            />
            <div className="notification-body">
              <p className="updates-notification-heading">No notifications right now</p>
              <div className="notification-date">
                <Moment format="MM/DD/YY">{new Date()}</Moment>
              </div>
            </div>
          </div>
          }

          {props.notification.map(not => (
            <div
              className={
                not.read ? "notification" : "notification unread-notification"
              }
            >

              <img
                className="notification-icon"
                alt="notificationIcon"
                src={"status" in not ? (not.status === "succeeded" ? "/images/Notification-Red_Icon.svg" :
                  (not.status === "failed" ? "/images/Notification-Red_Icon.svg" : "/images/Notification-Red_Icon.svg")) :
                  "/images/Notification-Red_Icon.svg"}
              />
              <div className="notification-body">
                <div onClick={() => !not.read && props.readNotification(not.id)}
                  className="notification-item">
                  <span className="notification-title">{not.title}</span>: <span className="paragraph-child">{not.message}</span>
                </div>


                <div className="notification-date">
                  <Moment format="MM/DD/YY">{not.created_date}</Moment>
                </div>
              </div>
            </div>
          ))}

        </>)}
    </Modal>
  );
};
export default Notification;
