import React, { useState } from "react";
import { Modal, Button } from "antd";
import { CircularProgress } from "@material-ui/core";
import { getUserEmail } from "../../helpers/utility";
import "../style.css";

const SharePopup = props => {
  const [shareDisabled, setShareDisabled] = useState(true);
  const [userEmails, setUserEmails] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [share_loading, setShareLoading] = useState(false);

  const share = e => {
    let users = userEmails.map(function (el) {
      return el.trim();
    });
    if (users && !users.some(r => props.existingUsers.includes(r))) {
      if (validate(users)) {
        if (
          users &&
          users.every(
            user =>
              user.includes("@qburst.com") ||
              user.includes("@gritstone.ai") ||
              user.includes("@seerinteractive.com")
          )
        ) {
          setShareLoading(true);
          let data = {
            organization_id: props.client.organization_id,
            // email: getUserEmail(),
            emails_to_share: users
          };
          setShareDisabled(true);
          props.share(data).then(() => {
            props.existingUsers.push(...userEmails);
            setShareLoading(false);
          });
        } else {
          setShareLoading(false);
          setErrorMessage(
            "Please share this project with seerinteractive.com(qburst.com) account(s)"
          );
        }
      } else {
        setErrorMessage("Wrong email(s)");
      }
    } else {
      setErrorMessage("Email already exists");
    }
  };

  const validate = users => {
    // eslint-disable-next-line no-useless-escape
    let regex = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    function isValid(element) {
      return regex.test(element);
    }
    let valid = users.every(isValid);
    return valid;
  };
  const handleCancel = () => {
    props.fetchProjects(getUserEmail());
    props.setsharePopupVisible(false);
  };
  const emailChange = email => {
    if (email) {
      setErrorMessage("");
      setUserEmails(email.replace(/\s*$/, "").split(","));
      setShareDisabled(false);
    }
  };
  const deleteUser = email => {
    let data = {
      organization_id: props.client.organization_id,
      // email: getUserEmail(),
      emails_to_delete: [email]
    };
    props.deleteUser(data).then(() => {
      let email = props.existingUsers.filter(
        el => !data.emails_to_delete.includes(el)
      );
      props.setExistingUsers(email);
    });
  };
  return (
    <Modal
      visible={props.sharePopupVisible}
      className="share-popup error-popup"
      closeIcon={(
        <img
          className="closeIcon"
          alt="closeIcon"
          src={"/images/Close-X_Icon.svg"}
          onClick={handleCancel}

        />
      )}
      footer={null}
    >
      <div className="top-title">Share this project</div>
      <div className="share-users-title">ADD NEW USERS BY EMAIL ADDRESS <span className="required">*</span></div>

      {share_loading ? (
        <CircularProgress />
      ) : (
        <textarea
          className={errorMessage ? "email-address-input input-error" : "email-address-input "}
          placeholder="Enter one or more email addresses separated by commas to share this project with new users"
          rows="4" cols="4"
          onChange={e => emailChange(e.target.value)}
          InputLabelProps={{ style: { fontSize: "15px", color: "#3A4245", fontFamily: 'Roboto-Regular', padding: "0px 16px" } }}
        />
      )}
      <span className="error-msg">{errorMessage}</span>
      <p className="existing-user-title">existing users on this project</p>
      {props.delete_loading ? (
        <CircularProgress />
      ) : (
        <div className="user-list">
          {props.existingUsers &&
            props.existingUsers.map(function (elem, index) {
              return (
                <li key={index + 1}>
                  {elem}
                  {elem !== getUserEmail() && (
                    <span
                      className="remove-icon"
                      onClick={item => deleteUser(elem)}
                    >Remove
                    </span>
                  )}
                </li>
              );
            })}
        </div>
      )}
      <div className="share-modal-footer">
        <Button key="submit" type="primary" disabled={shareDisabled} onClick={
          share
        }>
          SHARE
        </Button>
        <Button key="back" onClick={handleCancel} ghost>
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

export default SharePopup;
