import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { routerReducer, routerMiddleware } from 'react-router-redux';
import thunk from 'redux-thunk';
import reducers from '../redux/reducers';
import { createLogger } from 'redux-logger'; 


const history = require("history").createBrowserHistory;
const routeMiddleware = routerMiddleware(history);
const loggerMiddleware = createLogger();
const middlewares = process.env.NODE_ENV === 'production' ? [thunk, routeMiddleware] : [thunk, routeMiddleware, loggerMiddleware];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  combineReducers({
    ...reducers,
    router: routerReducer
  }),
  composeEnhancers(applyMiddleware(...middlewares))
);
export { store, history };
