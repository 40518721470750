import * as ACTION from "../actionTypes";

const initialState = {
  iframeUrl: "",
  url_loading: false,
  dashboardSlug: "",
  dashboardFilter: "",
};

export default function embeddedDashboardReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION.FETCH_EMBED_URL_BEGIN:
      return {
        ...state,
        url_loading: true,
        error: null
      };
    case ACTION.FETCH_EMBED_URL_SUCCESS:
      return {
        ...state,
        url_loading: false,
        error: null,
        iframeUrl: action.payload.data.data.embed_url,
        dashboardFilter: action.payload.data.data.filters,
      };
    case ACTION.FETCH_EMBED_URL_ERROR:
      return {
        ...state,
        url_loading: false,
      };
      case ACTION.FETCH_DASHBOARD_SLUG_BEGIN:
      return {
        ...state,
        url_loading: true,
        error: null
      };
    case ACTION.FETCH_DASHBOARD_SLUG_SUCCESS:
      return {
        ...state,
        url_loading: false,
        error: null,
        dashboardSlug: action.payload.data.data.dashboard_slug
      };
    case ACTION.FETCH_DASHBOARD_SLUG_ERROR:
      return {
        ...state,
        url_loading: false,
      };

    default:
      return state;
  }
}
