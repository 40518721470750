import React from "react";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import { fieldToTextField } from "formik-material-ui";

const FormikAutocomplete = ({ textFieldProps, ...props }) => {
  const {
    form: { setFieldValue }
  } = props;
  const { error, helperText, ...field } = fieldToTextField(props);
  const { name } = field;
  return (
    <Autocomplete
      {...props}
      {...field}
      onChange={(_, value) => {
        props.onChange && props.onChange(value);
        setFieldValue(name, value);
      }}
      // onBlur={() => setTouched({ [name]: true })}
      getOptionLabel={props.getOptionLabel}
      renderInput={props => (
        <TextField
          {...props}
          {...textFieldProps}
          helperText={helperText}
          error={error}
          label={field.label}
          variant="outlined"
          fullWidth
        />
      )}
    />
  );
};

export default FormikAutocomplete;
