import React from "react";
import { TextField } from "@material-ui/core";
import { fieldToTextField } from "formik-material-ui";

const FormikTextField = props => {
  const { error, helperText } = fieldToTextField(props);
  return (
    <>
      {props.singleLine ? (
        <TextField
          {...props.field}
          variant="outlined"
          label={props.label}
          fullWidth
          helperText={helperText}
          error={error}
        />
      ) : (
        <TextField
          {...props.field}
          variant="outlined"
          label={props.label}
          fullWidth
          multiline
          helperText={helperText}
          error={error}
          inputProps={{ maxLength: props?.maxLength }}
        />
      )}
    </>
  );
};

export default FormikTextField;
