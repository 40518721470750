import { SUPERNOVA_API, API_AUTH } from "../../api";
import * as ACTION from "../../redux/actionTypes";
import { clearAll } from "../../helpers/utility";

export function auth({errorCallback}) {
  return dispatch => {
    dispatch(authBegin());
    return SUPERNOVA_API.post(
      `${API_AUTH}`,
      {
        firebase_token: localStorage.getItem("firebase_token"),
        fcm_token: "123"
      },
      {}
    )
      .then(response => {
        let now = new Date().getTime();
        response.data?.data?.token
          ? localStorage.setItem("access_token", response.data.data.token)
          : clearAll();
        // localStorage.setItem(
        //   "firebase_token",
        //   firebase.auth().currentUser.ra
        //     ? firebase.auth().currentUser.ra
        //     : firebase.auth().currentUser.ma
        // );
        localStorage.setItem("setup_time", now);
        // localStorage.setItem("user_email", firebase.auth().currentUser.email);
        dispatch(authSuccess(response));
      })
      .catch(err => {
        dispatch(authError("Auth Error"));
        errorCallback && errorCallback();
      });
  };
}

export const authBegin = () => ({
  type: ACTION.AUTH_BEGIN
});

export const authSuccess = list => ({
  type: ACTION.AUTH_SUCCESS,
  payload: list
});

export const authError = () => ({
  type: ACTION.AUTH_ERROR,
  error: "Auth error"
});
