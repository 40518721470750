import { Typography } from "antd";
import React, { useEffect } from "react";
import { useState, useRef } from "react";
import { PrimaryButton } from "../RankTracking/style";
import { Grid, Input as InputField, ExpansionPanel } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ListView from "./widgets/listitem";

const FilterPopup = (props) => {
  const [filterText, setFilterValue] = useState("");
  const [filteredClient, setFilteredClient] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [isClient, setClientExpand] = useState(false);
  const [isProject, setProjectExpand] = useState(false);
  const {
    filterPopupVisible,
    clientArray,
    projectArray,
    filteredProjectData,
    filteredClientData,
    setFilteredClientData,
    setFilteredProjectData,
    setFilterPopupVisible,
    allProject,
  } = props;

  const [searchClient, setSearchClient] = useState([]);
  const [searchProject, setSearchProject] = useState([]);

  const ref = useRef(null);

  const closeExpand = () => {
    setClientExpand(false);
    setProjectExpand(false);
    setSearchClient([]);
    setSearchProject([]);
    setFilterValue('');
  }

  useEffect(() => {
    closeExpand();
  }, [filterPopupVisible]);

  useEffect(() => {
    if (filterPopupVisible) {
      setFilteredClient(clientArray.filter(e => filteredClientData.find(ele => ele === e.value)))
      setFilteredProjects(projectArray.filter(e => filteredProjectData.find(ele => ele === e.value)))
    }
  }, [filterPopupVisible, filteredClientData, filteredProjectData]);

  useEffect(() => {
    closeExpand();
  }, []);

  useEffect(() => {
    if (filterText) {

    }
  }, [filterText]);


  const handleFilterClick = (client, project) => {
    if (client?.length > 0) {
      setFilteredClientData(client.map(e => e.value));
    }
    setFilteredProjectData(project.map(e => e.value));
    setFilterPopupVisible(false);
  };

  const handleClientChange = e => {
    setFilteredClient(e);
    setProjectExpand(true);
  }

  const handleProjectChange = e => {
    setFilteredProjects(e);
  }

  const scrollToButton = (ref) => {
    ref.current.scrollIntoView(false);
  };

  const onSearch = (e) => {
    scrollToButton(ref);
    const { value } = e.target;
    if (!value || value === "") {
      setSearchClient([]);
      setSearchProject([]);
    }
    if (value) {
      const clientArr = clientArray.filter(ele => ele.label.toLowerCase().includes(value.toLowerCase()));
      const projectArr = projectArray.filter(ele => ele.label.toLowerCase().includes(value.toLowerCase()));
      setSearchClient(clientArr);
      setSearchProject(projectArr);
    }
    setFilterValue(e.target.value);
  };

  useEffect(() => {
    if (ref?.current) {
      scrollToButton(ref);
    }
  }, [searchClient, searchProject, filteredProjects, filteredClient]);

  const getClientOptions = (search, projects, clients, allP) => {
    let array = search?.length > 0 ? search : allP;
    if (clients?.length) {
      array = allP.filter(prj => clients.findIndex(client => client.value === prj.org_id) > -1);
    }
    return array;
  }

  return filterPopupVisible ? (
    <Grid
      container
      justifyContent="center"
      style={{
        minHeight: "490px",
        position: "absolute",
        zIndex: 2,
        background: "black",
        width: "100%",
        animationDirection: "alternate",
      }}
    >
      <Grid
        item
        xs={12}
        // justify="space-between"
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Grid container>
          <Grid
            container
            item
            xs={12}
            justifyContent="space-between"
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Grid container justifyContent="center" className="filter-section">
              <Grid item xs={10}>
                <FormControl fullWidth>
                  <InputField
                    color="white"
                    id="standard-adornment-password"
                    type="text"
                    fullWidth
                    onFocus={() => {
                      setClientExpand(true);
                      setProjectExpand(true);
                    }}
                    placeholder="Search for clients or projects"
                    style={{
                      color: "white",
                      borderBottom: "1px solid white",
                    }}
                    inputProps={{
                      style: {
                        fontSize: "14px",
                      },
                    }}
                    value={filterText}
                    onChange={onSearch}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => { }}
                          style={{
                            color: "white",
                          }}
                          onMouseDown={() => {
                            setFilterValue("");
                            setSearchClient([]);
                            setSearchProject([]);
                          }}
                        >
                          {filterText ? <CloseIcon /> : <SearchIcon />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container justifyContent="center" className="filter-section">
              <Grid item xs={10}>
                <ExpansionPanel className="expansion-panel" expanded={isClient} onChange={() => setClientExpand(!isClient)}>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon className="color-white" />}>
                    <Typography className="color-white">CLIENTS</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    {filterText && searchClient?.length === 0 ? (
                      <Typography variant="body2" className="no-options">No options match</Typography>
                    ) : (
                      <ListView options={searchClient?.length > 0 ? searchClient : clientArray} value={filteredClient} handleChange={handleClientChange} />
                    )}
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </Grid>
            </Grid>
            <Grid container justifyContent="center" className="filter-section">
              <Grid item xs={10}>
                <ExpansionPanel className="expansion-panel" expanded={isProject} onChange={() => setProjectExpand(!isProject)}>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon className="color-white" />}>
                    <Typography className="color-white">PROJECTS</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    {filterText && searchProject?.length === 0 && filteredClient.length === 0 ? (
                      <Typography variant="body2" className="no-options">No options match</Typography>
                    ) : (
                      <ListView options={getClientOptions(searchProject, projectArray, filteredClient, allProject)} value={filteredProjects} handleChange={handleProjectChange} />
                    )}
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-end">
          <Grid>
            <PrimaryButton ref={ref} className="filter-apply-button" onClick={() => handleFilterClick(filteredClient, filteredProjects)}>
              Apply filters
              <img
                src={"/images/Icon_Arrow-White.png"}
                alt="Black arrow icon"
              />
            </PrimaryButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <div></div>
  );
};

export default FilterPopup;
