import * as ACTION from "../actionTypes";

const initialState = {
  notification: [],
  project_loading: false,
  org_dept_loading: false,
  location_loading: false,
  country_code_loading: false,
  error: null,
  metadata: [],
  organization: [],
  departments: [],
  projects: [],
  status: null,
  delete_loading: false,
  share_loading: false,
  project_delete_error: null,
  share_status: false,
  isCreatedProject: false,
  accessible_clients: [],
  savedProjectId: null,
};
export default function dashboardReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION.FETCH_NOTIFICATION_BEGIN:
      return {
        ...state,
        error: null
      };
    case ACTION.FETCH_NOTIFICATION_SUCCESS:
      return {
        ...state,
        error: null,
        notification: action.payload.user_specific,
        count: action.payload.user_specific.filter(n => n.read === false).length
      };
    case ACTION.FETCH_NOIFICATION_ERROR:
      return {
        ...state,
        error: action.error,
        notification: []
      };
    case ACTION.FETCH_METADATA_BEGIN:
      return {
        ...state,
        error: null
      };
    case ACTION.FETCH_METADATA_SUCCESS:
      return {
        ...state,
        error: null,
        metadata: action.payload
      };
    case ACTION.FETCH_METADATA_ERROR:
      return {
        ...state,
        error: action.error,
        metadata: []
      };
    case ACTION.FETCH_ORGANIZATION_BEGIN:
      return {
        ...state,
        org_dept_loading: true,
        error: null
      };
    case ACTION.FETCH_ORGANIZATION_SUCCESS:
      return {
        ...state,
        error: null,
        clients: action.payload
      };
    case ACTION.FETCH_ORGANIZATION_ERROR:
      return {
        ...state,
        org_dept_loading: false,
        error: action.error,
        clients: []
      };
    // -----------------------------------
    case ACTION.FETCH_ACCESSIBLE_CLIENTS_BEGIN:
      return {
        ...state,
        error: null
      };
    case ACTION.FETCH_ACCESSIBLE_CLIENTS_SUCCESS:
      return {
        ...state,
        error: null,
        accessible_clients: action.payload
      };
    case ACTION.FETCH_ACCESSIBLE_CLIENTS_ERROR:
      return {
        ...state,
        error: action.error,
        accessible_clients: []
      };
    // -----------------------------------
    case ACTION.FETCH_DEPARTMENT_BEGIN:
      return {
        ...state,
        error: null
      };
    case ACTION.FETCH_DEPARTMENT_SUCCESS:
      return {
        ...state,
        error: null,
        org_dept_loading: false,
        divisions: action.payload
      };
    case ACTION.FETCH_DEPARTMENT_ERROR:
      return {
        ...state,
        org_dept_loading: false,
        error: action.error,
        divisions: []
      };
    case ACTION.FETCH_DEVICE_BEGIN:
      return {
        ...state,
        isDeviceLoading: true,
        error: null
      };
    case ACTION.FETCH_DEVICE_SUCCESS:
      return {
        ...state,
        isDeviceLoading: false,
        devices: action.payload
      };
    case ACTION.FETCH_DEVICE_ERROR:
      return {
        ...state,
        isDeviceLoading: false,
        error: action.error,
        devices: []
      };
    case ACTION.FETCH_PROJECT_BEGIN:
      return {
        ...state,
        project_loading: true,
        error: null,
        isCreatedProject: false
      };
    case ACTION.FETCH_PROJECT_SUCCESS:
      return {
        ...state,
        project_loading: false,
        error: null,
        projects: action.payload
      };
    case ACTION.FETCH_PROJECT_ERROR:
      return {
        ...state,
        project_loading: false,
        error: action.error,
        projects: []
      };
    case ACTION.SHARE_BEGIN:
      return {
        ...state,
        error: null,
        share_loading: true,
        share_status: false
      };
    case ACTION.SHARE_SUCCESS:
      return {
        ...state,
        error: null,
        share_loading: false,
        share_status: true
      };
    case ACTION.SHARE_ERROR:
      return {
        ...state,
        error: action.error,
        share_loading: false,
        share_status: false
      };
    case ACTION.DELETE_BEGIN:
      return {
        ...state,
        error: null,
        delete_loading: true
      };
    case ACTION.DELETE_SUCCESS:
      return {
        ...state,
        status: action.payload,
        error: null,
        delete_loading: false
      };
    case ACTION.DELETE_ERROR:
      return {
        ...state,
        status: null,
        error: action.error,
        delete_loading: false
      };
    case ACTION.SAVE_PROJECT_BEGIN:
      return {
        ...state,
        isLoadingCreateProject: true,
        error: null,
        isCreatedProject: false,
      };
    case ACTION.SAVE_PROJECT_SUCCESS:
      return {
        ...state,
        error: null,
        isLoadingCreateProject: false,
        isCreatedProject: true,
        savedProjectId: action.payload,
      };
    case ACTION.SAVE_PROJECT_ERROR:
      return {
        ...state,
        isLoadingCreateProject: false,
        isCreatedProject: false,
        savedProjectId: null,
      };
    case ACTION.FETCH_CACHED_ORG:
      return {
        ...state,
        organization: action.payload && JSON.parse(action.payload)
      };
    case ACTION.FETCH_CACHED_DEPT:
      return {
        ...state,
        departments: action.payload && JSON.parse(action.payload)
      };
    case ACTION.AUTH_BEGIN:
      return {
        ...state
        //loading: true,
      };
    case ACTION.AUTH_SUCCESS:
      return {
        ...state
        //loading: false,
      };
    case ACTION.AUTH_ERROR:
      return {
        ...state
        //	loading: false,
      };
    case ACTION.DELETE_PROJECT_BEGIN:
      return {
        ...state,
        project_loading: true,
        project_delete_error: null
      };
    case ACTION.DELETE_PROJECT_SUCCESS:
      return {
        ...state,
        project_loading: false,
        project_delete_error: null
      };
    case ACTION.DELETE_PROJECT_ERROR:
      return {
        ...state,
        project_loading: false,
        project_delete_error: action.error
      };
    case ACTION.FETCH_FREQUENCY_BEGIN: {
      return { ...state, isFrequencyLoading: true };
    }
    case ACTION.FETCH_FREQUENCY_SUCCESS: {
      const options = [...action.payload];
      options.push("All");
      return {
        ...state,
        isFrequencyLoading: false,
        frequencies: action.payload,
        downloadOptions: options
      };
    }
    case ACTION.FETCH_FREQUENCY_ERROR: {
      return { ...state, isFrequencyLoading: false };
    }
    case ACTION.FETCH_ENGINE_BEGIN: {
      return { ...state, isEngineLoading: true };
    }
    case ACTION.FETCH_ENGINE_SUCCESS: {
      return { ...state, isEngineLoading: false, engines: action.payload };
    }
    case ACTION.FETCH_ENGINE_ERROR: {
      return { ...state, isEngineLoading: false };
    }
    case ACTION.FETCH_LANG_BEGIN: {
      return { ...state, isLanguageLoading: true };
    }
    case ACTION.FETCH_LANG_SUCCESS: {
      return { ...state, isLanguageLoading: false, locale: action.payload };
    }
    case ACTION.FETCH_LANG_ERROR: {
      return { ...state, isLanguageLoading: false };
    }
    case ACTION.FETCH_TAGS_BEGIN: {
      return { ...state, isTagsLoading: true };
    }
    case ACTION.FETCH_TAGS_SUCCESS: {
      return { ...state, isTagsLoading: false, tags: action.payload };
    }
    case ACTION.FETCH_TAGS_ERROR: {
      return { ...state, isTagsLoading: false };
    }
    case ACTION.SET_SELECTED_PROJECT: {
      return { ...state, selectedProject: action.payload };
    }
    case ACTION.CLEAR_NOTFN_BEGIN: {
      return {
        ...state,
        isClearNotificationLoading: true
      };
    }
    case ACTION.CLEAR_NOTFN_SUCCESS: {
      return {
        ...state,
        isClearNotificationLoading: false,
        notification: [],
        count: 0
      };
    }
    case ACTION.CLEAR_NOTFN_ERROR: {
      return { ...state, isClearNotificationLoading: false };
    }
    case ACTION.FETCH_COUNTRY_BEGIN:
      return {
        ...state,
        country_code_loading: true,
        error: null
      };
    case ACTION.FETCH_COUNTRY_SUCCESS:
      return {
        ...state,
        country_code_loading: false,
        countryCodes: action.payload
      };
    case ACTION.FETCH_COUNTRY_ERROR:
      return {
        ...state,
        country_code_loading: false,
        error: action.error,
        countryCodes: []
      };
    case ACTION.FETCH_LOCATION_BEGIN:
      return {
        ...state,
        location_loading: true,
        error: null
      };
    case ACTION.FETCH_LOCATION_SUCCESS:
      return {
        ...state,
        location_loading: false,
        locations: action.payload
      };
    case ACTION.FETCH_LOCATION_ERROR:
      return {
        ...state,
        location_loading: false,
        error: action.error,
        locations: []
      };
    case ACTION.RESET_LOCATION:
      return {
        ...state,
        location_loading: false,
        locations: action.payload
      };
    case ACTION.FETCH_LOCATION_TYPE_BEGIN:
      return {
        ...state,
        location_loading: true,
        error: null
      };
    case ACTION.FETCH_LOCATION_TYPE_SUCCESS:
      return {
        ...state,
        location_loading: false,
        locationTypes: action.payload
      };
    case ACTION.FETCH_LOCATION_TYPE_ERROR:
      return {
        ...state,
        location_loading: false,
        error: action.error,
        locationTypes: []
      };
    case ACTION.RESET_LOCATION_TYPE:
      return {
        ...state,
        location_loading: false,
        locationTypes: action.payload
      };
    default:
      return state;
  }
}
