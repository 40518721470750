import React, { useState } from "react";
import {
  TextField,
  CircularProgress,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Upload, message, Button } from "antd";
import { getUserEmail } from "../../helpers/utility";
import "./style.css";
import ErrorPopup from "../shared/ErrorPopup";
import moment from "moment";
import { PrimaryButton } from "../RankTracking/style";

const BulkUpload = (props) => {
  const [fileList, setFileList] = useState([]);
  const [fileData, setFileData] = useState();
  const [schedule, setschedule] = useState();
  const [uploadMore, setUploadMore] = useState(false);
  const [uploadData, setUploadData] = useState(false);
  const [errorPopupOpen, setErrorPopupOpen] = useState(false);

  const { isUploading, isUploadSuccess, isFailedUpload } = props;

  const handleUpload = () => {
    setUploadMore(false);
    bulkUpload(fileData);
  };
  const bulkUpload = (file) => {
    props.bulkUpload(signUrl, file);
  };
  const beforeUpload = (file) => {
    setFileList([file]);
    getUrl(file);
    return false;
    // if (file.type !== "text/csv") {
    //   setFileList([]);
    //   message.error("Please upload a csv file");
    // } else {
    //   setFileList([file]);
    //   getUrl(file);
    // }
    // return false;
  };

  const onRemove = (file) => {
    setFileList([]);
  };
  const getUrl = (file) => {
    let data = {
      email: getUserEmail(),
      jupiter_project: selectedProject.project,
      file_name: file.name,
      project_id: selectedProject.project_id,
    };
    setUploadData({
      filename: file.name,
      submitted_date: moment(new Date()).utc().format("MM-DD-YYYY HH:mm:SS"),
      upload_method: "bulk_upload",
    });
    props.getUrl(data, setFileData(file));
  };
  const download = (schedule, projectId) => {
    props.download(
      schedule,
      projectId,
      () => callBack(),
      () => callBackFn(),
      () => successFn()
    );
  };
  const callBack = () => {
    message.info("File will be send later via email.");
  };
  const callBackFn = () => {
    message.error("Unable to download keywords! please try after sometime.");
  };
  const successFn = () => {
    message.success("File uploaded successfully");
    setschedule();
  };
  const { downloadOptions, signUrl, selectedProject } = props;

  return (
    <>
      {isUploading ? (
        <>
          <CircularProgress />
          <p className="loading-message center">Do not close this window.</p>
        </>
      ) : isUploadSuccess && !uploadMore ? (
        <div className="center">
          <p className="loading-message">
            <img
              className="success-icon"
              alt="success-icon"
              src="/images/Icon_Success.svg"
            />
            Upload complete. Your scoreboard will update in 90 minutes or less.
            <span
              className="upload-more-button"
              onClick={() => {
                setUploadMore(true);
              }}
            >
              {" "}
              Upload more search terms
            </span>
          </p>
        </div>
      ) : isFailedUpload && !uploadMore ? (
        <div className="center">
          <p className="loading-message">
            <img
              className="success-icon"
              alt="success-icon"
              src="/images/Alert_Icon.svg"
            />
            <div>
              Upload{" "}
              <span
                className="upload-more-button"
                onClick={() => setErrorPopupOpen(true)}
              >
                failed with errors
              </span>{" "}
              for file {uploadData?.file_name} via Bulk Upload.
            </div>
            <span
              className="upload-more-button"
              onClick={() => {
                setUploadMore(true);
              }}
            >
              Try again
            </span>
          </p>
        </div>
      ) : (
        <div className="bulk-upload-wrapper">
          {/* <div className="bulk-upload-desc-wrapper"> */}
          <div className="half-width-wrapper">
            <div className="halfWidth bulk-upload-desc-wrapper">
              <p className="bulk-desc-header">
                Upload search terms using a CSV
              </p>
              <ul>
                <li className="bulk-desc-body">
                  Access the{" "}
                  <a onClick={props.templateDownload}>CSV template</a> for the
                  correct formatting
                </li>
                <li className="bulk-desc-body">
                  Bulk upload search terms using the CSV template
                </li>
              </ul>
            </div>
            <div className="halfWidth">
              <p className="bulk-desc-header">
                Edit existing search terms using a CSV
              </p>

              <ul>
                <li className="bulk-desc-body">
                  Download search terms by frequency or all search terms to edit
                  them
                </li>
                <li className="bulk-desc-body">
                  Make changes in your file and upload them again
                </li>
              </ul>
            </div>
          </div>
          <div className="half-width-wrapper">
            <div className="halfWidth">
              <div className="grid-label">Select CSV</div>
              <div className="upload-wrapper upload-label-wrapper">
                <Upload
                  name="file"
                  accept=".csv"
                  beforeUpload={beforeUpload}
                  onRemove={onRemove}
                  multiple={false}
                  fileList={fileList}
                  className="upload-label"
                >
                  <Button fullWidth className="select-csv">Select file</Button>
                </Upload>

                <div>
                  {isFailedUpload ? (
                    <PrimaryButton
                      disabled={signUrl && fileList.length !== 0 ? false : true}
                      onClick={() => handleUpload()}
                      loading={isUploading}
                    >
                      {isUploading ? "Uploading" : "Retry"}
                      <img
                        src={"/images/Icon_Arrow-White.png"}
                        alt="Black arrow icon"
                      />
                    </PrimaryButton>
                  ) : (
                    <PrimaryButton
                      disabled={signUrl && fileList.length !== 0 ? false : true}
                      onClick={() => handleUpload()}
                      loading={isUploading}
                    >
                      {isUploading ? "UPLOADING" : "UPLOAD"}
                      <img
                        src={"/images/Icon_Arrow-White.png"}
                        alt="Black arrow icon"
                      />
                    </PrimaryButton>
                  )}
                </div>
              </div>
            </div>
            <div className="halfWidth">
              <span className="grid-label">Frequency</span>
              <div className="download-wrapper">
                <Autocomplete
                  id="combo-box-demo"
                  options={downloadOptions}
                  style={{ minwidth: 300, height: 44 }}
                  onChange={(e, val) => setschedule(val)}
                  defaultValue={schedule}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="frequency"
                      label="Select frequency"
                      variant="outlined"
                      fullWidth
                      InputLabelProps={{
                        style: {
                          color: "#3a4245",
                          fontSize: "15px",
                        },
                      }}
                    />
                  )}
                />
                <PrimaryButton
                  loading={props.isLoadingDownload}
                  onClick={() => {
                    download(schedule, props.selectedProject.project_id);
                  }}
                  disabled={!schedule}
                >
                  {props.isLoadingDownload ? "DOWNLOADING" : "DOWNLOAD"}
                  <img
                    src={"/images/Icon_Arrow-White.png"}
                    alt="Black arrow icon"
                  />
                </PrimaryButton>
              </div>
            </div>
          </div>
        </div>
      )}
      <ErrorPopup
        errorData={"Unable to upload file"}
        errorPopupOpen={errorPopupOpen}
        setErrorPopupOpen={setErrorPopupOpen}
        footer={false}
        uploadData={uploadData}
      />
    </>
  );
};

export default BulkUpload;
