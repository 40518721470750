import Dashboard from './rankTracking/reducer';
import Project from '../redux/project/reducer';
import Auth from '../redux/Auth/reducer';
import EmbeddedDashboard from '../redux/embeddedDashboard/reducer';

export default {
    Dashboard,
    Project,
    Auth,
    EmbeddedDashboard
}
