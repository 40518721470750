import { List, ListItemIcon, ListItemText, ListItem } from "@material-ui/core";
import Checkbox from '@material-ui/core/Checkbox';
import styled from "styled-components";

export const StyledList = styled(List)`
    overflow-y: auto !important;
    overflow-x: hidden;
    
    max-height: 260px !important;
`;
export const StyledCheckBox = styled(Checkbox)`
    color: white !important;
`;
export const StyledListItemIcon = styled(ListItemIcon)``;
export const StyledListItemText = styled(ListItemText)``;
export const StyledListItem = styled(ListItem)`
    padding-Left: 0 !important;
`;
