import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  customTootltip: {
    maxWidth: 213,
    backgroundColor: "#000",
    boxShadow: "0 2px 10px 0 rgba(0,0,0,0.5)",
    fontSize: 13,
    padding: 15,
  },
  customArrow: {
    color: "#000",
  },
}));

export default function CustomToolTip({ title }) {
  const classes = useStyles();
  return (
      <Tooltip
        title={title}
        classes={{
          tooltip: classes.customTootltip,  //styles applied for tooltip
          arrow: classes.customArrow,
        }}
        arrow
      >
        <img
          src="/images/Icon-Tooltip.png"
          alt="tooltip-icon"
          className="tooltip-icon"
        />
      </Tooltip>
  );
}
