import React from "react";
import { StyledList, StyledCheckBox, StyledListItemIcon, StyledListItemText, StyledListItem } from "./style";
import { Typography } from "antd";


const ListView = ({
    options = [],
    value = [],
    handleChange = () => {},
}) => {
    const handleToggle = (currentValue) => () => {
        const currentIndex = value.indexOf(currentValue);
        const newChecked = [...value];
    
        if (currentIndex === -1) {
          newChecked.push(currentValue);
        } else {
          newChecked.splice(currentIndex, 1);
        }
        handleChange(newChecked);
      };
  return (
    <StyledList>
      {options?.length ? options.map((currentValue) => {
        const labelId = `checkbox-list-label-${currentValue?.value}`;
        return (
          <StyledListItem
            key={currentValue?.value}
            dense
            button
            onClick={handleToggle(currentValue)}
          >
            <StyledListItemIcon>
              <StyledCheckBox
                edge="start"
                checked={value.indexOf(currentValue) !== -1}
                tabIndex={-1}
                disableRipple
                inputProps={{ "aria-labelledby": labelId }}
              />
            </StyledListItemIcon>
            <StyledListItemText id={labelId} primary={currentValue?.label} />
          </StyledListItem>
        );
      }) : (
        <Typography variant="body2" className="no-options">No options match</Typography>
      )}
    </StyledList>
  );
};

export default ListView;
