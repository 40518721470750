import styled from "styled-components";

export const AppButton = styled.button`
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
  border: 1px solid #000000;
  border-radius: 18px;
  margin-top: 14px;
  padding: 8.5px 20px;
  cursor: pointer;
  img {
    width: 17.62px;
    margin-left: 10px;
  }
  font-family: Roboto-Medium;
  width: fit-content;
`;

export const PrimaryButton = styled(AppButton)`
  background-color: #000000;
  color: #ffffff;
  width: 100%;
`;